import { withTranslation } from "react-i18next";

import Layout from '../../Layout';
import Service from '../../Service';
import Error from '../Error';
import Loading from '../Loading';

import {
	Form,
	Col,
	// Button,
	// InputGroup,
	// Dropdown,
	// DropdownButton,
} from 'react-bootstrap';
import Button from '@mui/material/Button';

import "../Assets/css/linkage.css";
import logo from '../Assets/img/Logo2.svg';

class GuestLinkage extends Layout {

	constructor(props) {
		super(props);

		this.state.loading = true;
		this.state.docInfo = null;
		this.state.token = props.match.params.token;
		this.state.msgPageError = null;
		this.state.fields = {};
		this.state.keyFields = {
			linkage_user_id: "user-id",
			linkage_tenant_id: "tenant-selection",
			linkage_option: "option-selection",
		}
	}

	componentDidMount() {
		console.log("DocumentSign->componentDidMount");
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;

		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:document.linkage.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields, formData }, () => {
			let { token } = this.state;
			Service.GuestLinkageUserInfo(token).then(resp => {
				console.log(resp);
				if(!resp.response.data.hasOwnProperty("error")){
					let { UserInfo, docInfo, formData } = this.state;

					UserInfo = resp.user_info;
					docInfo = resp.doc_info;
	
					if (UserInfo.user_linkage) {
						formData["linkage_user_id"] = UserInfo.linkage_user_id || UserInfo.user_linkage.user_id;
						formData["linkage_tenant_id"] = UserInfo.linkage_tenant_id || UserInfo.user_linkage.tenants[0].id;
						formData["linkage_option"] = UserInfo.linkage_option || 0;
					}
	
					this.setState({ UserInfo, docInfo, formData, loading: false });
				}else{
					let msgPageError = "auth.general.expired-token-url";
					if (resp.response.data.hasOwnProperty("error")) {
						msgPageError = resp.response.data.error;
					}
					this.setState({ verified: false, loading: false, msgPageError });
				}
				
			}).catch(err => {
				console.log(err.response);
				let msgPageError = "auth.general.expired-token-url";
				if (err.response.data.hasOwnProperty("error")) {
					msgPageError = err.response.data.error;
				}
				this.setState({ verified: false, loading: false, msgPageError });
			});
		});
	}

	Nav = (props) => {
		return null;
	}

	TenantsChildren = () => {
		let { UserInfo } = this.state;

		if (!UserInfo || !UserInfo.user_linkage) {
			return [];
		}

		let ItemElements = [];
		for (let item of UserInfo.user_linkage.tenants) {
			ItemElements.push(<option key={item.id} value={item.id}>{item.name}</option>);
		}

		return ItemElements;
	}

	LinkageApproveSubmit = (ev) => {
		let { t } = this.props;
		let { token, formData } = this.state;
		Service.GuestLinkageUserApprove(token, formData).then((resp) => {
			console.log(resp);
			let { modal, UserInfo, docInfo } = this.state;

			UserInfo = resp.user_info;
			docInfo = resp.doc_info;

			modal.body = "document.linkage.msg-success";
			this.setState({ modal, UserInfo, docInfo });
		}).catch((err) => {
			console.log(err.response);
			let { modal } = this.state;
			modal.body = "document.linkage.msg-failure";
			if (err.response.data.hasOwnProperty("error")) {
				modal.body = err.response.data.error;
			} else if (err.response.data.hasOwnProperty("message")) {
				modal.body = err.response.data.message;
			}
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				modal.body = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			} 
			modal.action = [
				<Button key="ok" variant="contained" onClick={(ev) => {
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.LinkageApproveSubmit);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			];

			this.setState({ modal });
		});
	}

	formSubmitCallback = (ev) => {
		let { modal, formData } = this.state;

		console.log("formData", formData);

		modal.title = "document.linkage.header";
		modal.body = Loading;
		modal.action = [];

		modal.props.show = true;
		modal.props.size = "xl";
		modal.props.className = "layout modal-responsive";

		modal.props.centered = true;

		formData.linkage_option = 1; // force to auto

		this.setState({ modal, formData }, this.LinkageApproveSubmit);
	}

	LinkageTanantField = () => {
		let { t } = this.props;
		let { UserInfo } = this.state;
		let { FieldControl } = this;

		if (!UserInfo || !UserInfo.user_linkage) {
			return null;
		}

		if (UserInfo.linkage_user_id && UserInfo.linkage_tenant_id) {
			let linkageTenant = null
			let linkageUser = UserInfo.user_linkage;
			for (let item of UserInfo.user_linkage.tenants) {
				if (UserInfo.linkage_tenant_id === item.id) {
					linkageTenant = item;
					break;
				}
			}

			if (!linkageTenant) {
				linkageTenant = {
					id: UserInfo.linkage_tenant_id,
					name: "Unknow tenant name",
				}
			}

			return (
				<Form.Row>
					<Form.Group as={Col} className="custom-form-outline">
						<Form.Label>{t("common:document.linkage.linkange-info-label")}</Form.Label>
						<Form.Label>
							<table>
								<tbody>
									<tr><td>{t("common:document.linkage.user-id")}</td><td>{linkageUser.user_id}</td></tr>
									<tr><td>{t("common:document.linkage.user-name")}</td><td>{linkageUser.full_name}</td></tr>
									<tr><td>{t("common:document.linkage.tenant-id")}</td><td>{linkageTenant.id}</td></tr>
									<tr><td>{t("common:document.linkage.tenant-name")}</td><td>{linkageTenant.name}</td></tr>
								</tbody>
							</table>
						</Form.Label>
					</Form.Group>
				</Form.Row>
			);
		} else {
			return (
				<Form.Row>
					<FieldControl name="linkage_tenant_id" as="select" xs={12} md={6} children={this.TenantsChildren()} />
				</Form.Row>
			);
		}

	}

	LinkageOperationActions = () => {
		let { t } = this.props;
		let { UserInfo, docInfo } = this.state;

		if (UserInfo.linkage_user_id && UserInfo.linkage_tenant_id) {
			if (docInfo && docInfo.linkage_doc_id) {
				return (
					<Button variant="contained" type="button" href={`/documents/detail/${docInfo.linkage_doc_id}`}>
						<span>{t('common:document.linkage.btn-open-clone-document')}</span>
					</Button>
				);
			} else {
				return null;
			}
		} else {
			return (
				<Button variant="contained" type='submit'>
					<span>{t('common:document.linkage.btn-approve')}</span>
				</Button>
			);
		}
	}

	render() {
		let { t } = this.props;
		let { token, UserInfo, loading, formData, msgPageError, verified } = this.state;
		let { GeneralModal, FormModal, LinkageTanantField, LinkageOperationActions } = this;

		if (loading) {
			return <Loading className="full-screen" />;
		}

		if (!UserInfo) {
			return <Error code={404} msg={t(`common:${msgPageError || 'message.error.server_unknown_error'}`)} layout={!token} />;
		}

		if (!verified) {
			return <Error code={''} msg={t(`common:${msgPageError || 'message.error.server_unknown_error'}`)} layout={!token} />;
		}

		let linkageMessage = t("common:document.linkage.msg-confirm");
		if (UserInfo.linkage_user_id && UserInfo.linkage_tenant_id) {
			linkageMessage = t("common:document.linkage.msg-success");
		}

		return (
			<div className="linkage contrainer-wrapper">
				<div className="horizontal">
					<div className="contrainer-block">

						<div className="form-wrapper registration" style={{ "width": "600px" }}>

							<div className="logo-horizontal">
								<img src={logo} className="App-logo" alt="paperlogic" />
							</div>
							<Form
								// validated={formValidate} 
								id={"form_resend_email"}
								onSubmit={(ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) }}
								noValidate
							>

								<div className="form-header">
									<div className="header-info">
										<h3>{t("common:document.linkage.header")}</h3>
									</div>
								</div>

								<div className="form-container">

									<Form.Row>
										<Form.Group as={Col} className="custom-form-outline">
											<Form.Label>{linkageMessage}</Form.Label>
										</Form.Group>
									</Form.Row>

									<LinkageTanantField />

									<Form.Row style={{ display: "none" }}>
										<Form.Group as={Col} className="custom-form-outline">
											<Form.Label>{t("common:document.linkage.option-selection")}</Form.Label>
											{['always-ask', 'auto', 'manual'].map((value, index) => (
												<Form.Check inline type="radio" key={value}
													label={value}
													value={index}
													name="linkage_option"
													id={`linkage_option_${index}`}
													checked={formData.linkage_option === index}
													onChange={(ev) => {
														let { formData } = this.state;
														formData.linkage_option = index;
														console.log(formData);
														this.setState({ formData });
													}}
												/>
											))}
										</Form.Group>
									</Form.Row>
								</div>

								<div className="form-bottom">
									<div className="wrapper-button-cancel"></div>
									<div className="wrapper-button-submit">
										<LinkageOperationActions />
									</div>
								</div>

							</Form>

						</div>

					</div>

					<div className="footer">
						<div>© {new Date().getFullYear()} paperlogic.co.jp</div>
					</div>
				</div>

				<GeneralModal />

				<FormModal />

			</div >
		);
	}

}

export default withTranslation()(GuestLinkage);
