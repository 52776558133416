// @ts-nocheck
import { faCheck, faExclamationTriangle, faMinus, faPlus, faQuestion, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hookstate, StateFragment } from '@hookstate/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from "@mui/material";
import { createRef, Suspense, useMemo, useState } from 'react';
import {
	Col, Form, InputGroup,
	Modal, Button
} from 'react-bootstrap';
import MuiButton from "@mui/material/Button";
import { Page, pdfjs } from 'react-pdf';
import BaseSuggestionPopover from "../../Components/base-suggestion/base-suggestion";
import { searchBusinessNumber } from "../../data-access/business-number/business-number.api";
import DriveLayout from '../../drive-feature/components/drive-layout/drive-layout';
import { getFoldersTree } from '../../drive-feature/data-access/api';
import { currentSelectedCompanyFolderState, selectedFoldersCompany } from '../../drive-feature/data-access/company-folders/company-folders.state';
import { breabCrumbState, currentFolderTypeState, driveDetailLoading, driveFoldersNav } from '../../drive-feature/data-access/state';
import DriveFolders from '../../drive-feature/pages/drive-folders/drive-folders';
import mappedDataToTreeView from '../../drive-feature/utils/mappedDataToTreeView';
import { replacerFunc } from '../../Utils/init_observer';
import Loading from '../Loading';
import { BusinessDetailField } from "../Main/BusinessNumberSearch";
import DocumentWithObserver from './DocumentWithObserver';
const currentRangePage = hookstate('1');
export default class DocumentMultipleInfo {

	refDataList = createRef();
	static get key() {
		return "document-info";
	}
	static get name() {
		return "DocumentMultipleInfo";
	}
	static get code() {
		return DocumentMultipleInfo.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = DocumentMultipleInfo.key;
		this.name = DocumentMultipleInfo.name;
		this.code = DocumentMultipleInfo.code;
		this.formData = {
			document_type: "common:documents.document-info.document-type",
			doc_title: "common:documents.document-info.doc-title",
			invoice_number: 'common:documents.document-info.invoice-number',
			amount: "common:documents.document-info.amount",
			currency: "common:documents.document-info.currency",
			sign_level: "common:documents.document-info.sign-level",
			display_imprint: "common:documents.document-info.display-imprint",
			contract_date: "common:documents.document-info.contract-date",
			expiry_date: "common:documents.document-info.expiry-date",
			document_create_date: "common:documents.document-info.document-create-date",
			// received_date: "common:documents.document-info.received-date",
			received_date: "common:documents.document-info.deal-date",
			// deal_date: "common:documents.document-info.deal-date",
			counter_party_name: "common:documents.document-info.counter-party-name",
			contract_counter_party_name: "common:documents.document-info.contract-counter-party-name",
			product: "common:documents.document-info.product",
			reference: "common:documents.document-info.reference",
			filebox_shortcut: "common:documents.document-info.shortcut-to-filebox",
			doc_attributes: "common:documents.document-info.attributes",
			content: "common:documents.document-info.content",
			preservation_require: "common:documents.document-info.preservation-require",
		};
		this.tempSelectedFilebox = null;
		this.initialValues = {
			// document_type: "contract",
			// currency: "JPY",
			display_imprint: true,
			doc_attributes: [],
			doc_title: "",
			invoice_number: this.objMain.state.suggestionData[this.objMain.state.PDFFile?.hash]?.invoice_number || '',
			amount: "0",
			filebox_shortcut: "",
		}

		this.objMain.setPageObject(this);
		this.objMain.formRef = createRef();
		// console.log('okok', this.objMain.state.PDFFiles)
		// console.log('run here')
		// this.checkTitle();
		// this.objMain.setState({observer : initObserver(currentRangePage)})
        // this.objMain.observer = initObserver(currentRangePage);
	}
	customValidity = (ev = null, type) => {
		let { PDFFiles, PDFFile, formData, creationType, requiredField, isInvalid } = this.objMain.state;
		console.log('customValidity', formData , type);
		if(formData.preservation_require == 1){
			console.log('123 run here')
			requiredField["received_date"] = false;
			requiredField["amount"] = false;
			requiredField["counter_party_name"] = false;
			isInvalid.received_date = false
			isInvalid.counter_party_name = false
			isInvalid.amount = false
			console.log(isInvalid);
			
			this.objMain.setState({requiredField: {...requiredField}, isInvalid}, ()=>{

					this.objMain.formRef.current?.querySelector('#received_date')?.removeAttribute('required');
					this.objMain.formRef.current?.querySelector('#amount')?.removeAttribute('required');
					this.objMain.formRef.current?.querySelector('#counter_party_name')?.removeAttribute('required');
					this.objMain.formRef.current?.querySelector('#received_date')?.setAttribute('style',  "")
					this.objMain.formRef.current?.querySelector('#amount')?.setAttribute('style',  "")
					this.objMain.formRef.current?.querySelector('#counter_party_name')?.setAttribute('style',  "")
					this.objMain.formRef.current?.querySelector('#received_date')?.checkValidity();
					this.objMain.formRef.current?.querySelector('#amount')?.checkValidity();
					this.objMain.formRef.current?.querySelector('#counter_party_name')?.checkValidity();
					console.log(this.objMain.formRef.current?.querySelector('#received_date'));
				});
		} else {
			requiredField["received_date"] = false;
			requiredField["amount"] = false;
			requiredField["counter_party_name"] = false;
			console.log('received_date', !!formData?.received_date );
			console.log('amount', !!formData?.amount );
			isInvalid.received_date = !formData?.received_date
			isInvalid.counter_party_name = !formData?.counter_party_name
			isInvalid.amount = !formData?.amount

			
			console.log(isInvalid);

			this.objMain.setState({requiredField: {...requiredField}, isInvalid}, ()=>{
				this.objMain.formRef.current?.querySelector('#received_date')?.setAttribute('required',  "true");
				this.objMain.formRef.current?.querySelector('#amount')?.setAttribute('required',  "true");
				this.objMain.formRef.current?.querySelector('#counter_party_name')?.setAttribute('required',  "true");
				this.objMain.formRef.current?.querySelector('#received_date')?.setAttribute('style',  "")
				this.objMain.formRef.current?.querySelector('#amount')?.setAttribute('style',  "")
				this.objMain.formRef.current?.querySelector('#counter_party_name')?.setAttribute('style',  "")
				this.objMain.formRef.current?.querySelector('#received_date')?.checkValidity();
				this.objMain.formRef.current?.querySelector('#amount')?.checkValidity();
				this.objMain.formRef.current?.querySelector('#counter_party_name')?.checkValidity();
			});

		}
		let allValidity = true;
		for (let key in formData) {
			PDFFile.formData[key] = formData[key];
		}
		
		// PDFFile.formData.doc_title = formData.doc_title;
		// PDFFile.formData.amount = formData.amount;
		// PDFFile.formData.received_date = formData.received_date;
		// PDFFile.formData.counter_party_name = formData.counter_party_name;
		for (let key in PDFFiles) {
			let PDFFileCheck = PDFFiles[key];
	
			// console.log('cehck',PDFFile.formData.doc_title,PDFFile.formData.amount,PDFFile.formData.received_date,PDFFile.formData.counter_party_name , creationType)
			if (!PDFFileCheck.formData) {
				PDFFileCheck.validity = false;
				allValidity = false;
			} else {
				PDFFileCheck.validity = true;
				if (creationType !== "verification" || ![1, "1"].includes(PDFFileCheck.formData.preservation_require)) {
					let validate = [];
					validate.push(PDFFileCheck.formData.doc_title ? true : false);
					validate.push(PDFFileCheck.formData.amount ? true : false);
					validate.push(PDFFileCheck.formData.received_date ? true : false);
					validate.push(PDFFileCheck.formData.counter_party_name ? true : false);
					if (validate.includes(false,) ) {
						PDFFileCheck.validity = false;
						if(PDFFile.hash === PDFFileCheck.hash){

							allValidity = false;
						}
					}
				}
			}
		}

		this.objMain.updateState({ PDFFiles });

		return allValidity;
	}

	formSubmitCallback = (ev) => {
		if (this.customValidity(ev)) {
			let { pageState } = this.objMain.state;
			pageState[this.name].completed = true;
			this.objMain.setFormData(this.formData);
			this.objMain.updateState({ pageState: pageState });
			this.objMain.nextPageStep(ev, this.constructor);
		}
	}

	/** */
	addAttribute = (ev) => {
		/* */
		// let key = "Note" + this.formData.doc_attributes.length;
		this.formData.doc_attributes.push({ key: "", value: "" });
		this.objMain.setFormData(this.formData);
	}

	removeAttribute = (ev) => {
		/* */
		// console.log(ev.target.closest(".remove").dataset);
		let {PDFFiles} = this.objMain.state;
		let row = parseInt(ev.target.closest(".remove").dataset.row);
		// console.log(row)
		delete PDFFiles[row];
		// return;
		this.formData.doc_attributes.splice(row, 1);
		this.objMain.setFormData(this.formData);
		this.objMain.setState({PDFFiles})
	}

	setAttibute = (ev) => {
		// console.log(ev);
		// console.log(ev.target.value);
		let row = parseInt(ev.target.dataset.row);
		let type = ev.target.dataset.type;
		if (type === "key") {
			this.formData.doc_attributes[row].key = ev.target.value;
		} else {
			this.formData.doc_attributes[row].value = ev.target.value;
		}
		this.objMain.setFormData(this.formData);
	}

	renderAttributes = (ev) => {
		let { t } = this.props;
		let { fieldValidate } = this.objMain.state;
		let attrRows = [];
		// console.log(this.formData.doc_attributes);
		for (let i in this.formData.doc_attributes) {
			let attr = this.formData.doc_attributes[i];
			attrRows.push(
				<Form.Row key={i}>
					{/* {FieldControl({ name: "amount", type: "number", step: "0.01", xs: 3, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, required: true, children: this.CurrencyItems() })} */}

					<Form.Group xs={4} as={Col} controlId={"key[" + i + "]"} className={fieldValidate[this.name]}>
						<Form.Control
							name={"key[" + i + "]"}
							value={attr.key}
							data-row={i}
							data-type="key"
							onChange={this.setAttibute}
							placeholder={t("common:documents.document-info.key")}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group xs={8} as={Col} controlId={"value[" + i + "]"} className={fieldValidate[this.name]}>
						<InputGroup hasValidation>
							<Form.Control
								name={"value[" + i + "]"}
								value={attr.value}
								data-row={i}
								data-type="value"
								onChange={this.setAttibute}
								placeholder={t("common:documents.document-info.value")}
								required
							/>
							<button type="button" data-row={i} className="btn-icon remove" onClick={this.removeAttribute}>
								<FontAwesomeIcon className="file-remove" icon={faTrash} />
							</button>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>

				</Form.Row>

			);
		}

		return attrRows;
	}

	showFileboxPathSelection = async (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			"dialogClassName": "modal-full zero-padding",
			"centered": true,
		}
		modalTitle = "common:documents.document-info.filebox-select-path";
		ModalAction = [];
		ModalBody = Loading;
		ModalAction.push(
			<Button key="ok" sx={{ ml: 1 }} variant="primary" onClick={(ev) => {
				// this.autoAddImprints(ev);
					let { PDFFile } = this.objMain.state;
					this.formData.filebox_shortcut = this.tempSelectedFilebox || '';
					// console.log(selectedFoldersCompany.get({stealth: true}), breabCrumbState.get({noproxy: true}));
					if (!selectedFoldersCompany.get({stealth: true})){
						if(breabCrumbState.get({noproxy: true}).length > 1){
							this.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length)]
								.reduce((arr,item)=>{
									if(!arr.find(c=> c.id == item.id)) arr.push(item);
									return arr;
								},[]).map(a=> {
									if (a.id === 'company'){
										return '';
									}
									return a.name;
								}).join('/') + '/';
							this.formData.shared_folders_path = this.formData.filebox_shortcut;
							this.formData.drive_folder_id = breabCrumbState.get({noproxy: true})[breabCrumbState.get({noproxy: true}).length - 1]?.id || null;
							
						} else {
							this.formData.filebox_shortcut = '/';
							this.formData.drive_folder_id = breabCrumbState.get({noproxy: true})[0].id;
							this.formData.shared_folders_path = '/';
						}
					} else {
						this.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length), 
							{...selectedFoldersCompany.get({stealth: true})}].reduce((arr,item)=>{
								if(!arr.find(c=> c.id == item.id)) arr.push(item);
							return arr;
						},[]).map(a=> {
							if (a.id === 'company'){
								return '';
							}
							return a.name;
						}).join('/') + '/';
						this.formData.shared_folders_path = this.formData.filebox_shortcut || '';
						this.formData.drive_folder_id = selectedFoldersCompany.get({stealth: true})?.id || '';
					}
					// console.log(selectedFoldersCompany.get({stealth: true}), breabCrumbState.get().length, )
					breabCrumbState.set([]);
					currentFolderTypeState.set(0);
					// let formData = this.formData;
					PDFFile.formData = {...this.formData};
					PDFFile.formData['shared_folders_path'] = this.formData.shared_folders_path || '';
					PDFFile.formData['filebox_shortcut'] = this.formData.filebox_shortcut || '';
					PDFFile.formData['drive_folder_id'] = this.formData.drive_folder_id || '';
					this.objMain.updateState({PDFFile, formData: this.formData});
					currentSelectedCompanyFolderState.set(0);
					this.objMain.setFormData(this.formData);
					driveFoldersNav.set([]);
					this.objMain.modalToggle(false);
					selectedFoldersCompany.set(null);
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);
		this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps });
		this.fileBoxSelectionBody({
			path: "/",
			type: "D",
		});

	}

	fileBoxSelectionCallback = (resp) => {
		// console.log(resp);
		this.tempSelectedFilebox = resp;
	}

	fileBoxSelectionBody = (options) => {
		let { ModalBody } = this.objMain.state;
		const breabCrumbDefault = driveFoldersNav?.get({ stealth: true, noproxy: true })?.find((a) => a.type === 6);
		driveDetailLoading.set(true);
		if (!breabCrumbDefault) {
			getFoldersTree({
				page_number: 1,
				page_size: 100,
				parent_id: "null",
			}).then(r => {
				// console.log(r.data.payload)
				breabCrumbState.set([r.data.payload.find(a => a.type === 6)].map(mappedDataToTreeView));
				ModalBody = () => {
					return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true} />} /></Suspense>;
				};
				this.objMain.updateState({ ModalBody });
			});
		} else {
			breabCrumbState.set([breabCrumbDefault]);
			driveDetailLoading.set(false);
			ModalBody = () => {
				return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true} />} /></Suspense>;
			};
			this.objMain.updateState({ ModalBody });
		}

		// this.setState({ FileboxStorage });
	}

	CurrencyItems = () => {
		let { PageData } = this.objMain.state;
		let { currencies } = PageData;
		let OptionItems = [];
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { doc_types } = PageData;

		let ItemElements = [];
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	PreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	SignLevelItems = () => {
		let { t } = this.props;
		let items = {
			0: "soft",
			1: "mixed",
			2: "strong",
		}

		let ItemElements = [];
		for (let i in items) {
			ItemElements.push(<option key={i} value={i}>{t(`common:documents.document-info.sign-level-${items[i]}`)}</option>);
		}

		return ItemElements;
	}

	SignLevelCallback = () => {
		let { formData } = this.objMain.state;

		// console.log(formData.sign_level);
		if (formData.sign_level.toString() === "0") {
			formData.use_digital_certificate = false;
		} else if (formData.sign_level.toString() === "2") {
			formData.use_digital_certificate = true;
		}
		// console.log(formData.use_digital_certificate);
		this.objMain.setFormData(formData);
	}
	DateFromMetadata = (value) => {
        if (!value) {
            return null;
        }
        try {
            // console.log(value);
            let pdfDateStringRegex = new RegExp("^D:" + "(\\d{4})" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "([Z|+|-])?" + "(\\d{2})?" + "'?" + "(\\d{2})?" + "'?");

              const matches = pdfDateStringRegex.exec(value);
          
              if (!matches) {
                return null;
              }
          
              const year = parseInt(matches[1], 10);
              let month = parseInt(matches[2], 10);
              month = month >= 1 && month <= 12 ? month - 1 : 0;
              let day = parseInt(matches[3], 10);
              day = day >= 1 && day <= 31 ? day : 1;
              let hour = parseInt(matches[4], 10);
              hour = hour >= 0 && hour <= 23 ? hour : 0;
              let minute = parseInt(matches[5], 10);
              minute = minute >= 0 && minute <= 59 ? minute : 0;
              let second = parseInt(matches[6], 10);
              second = second >= 0 && second <= 59 ? second : 0;
              const universalTimeRelation = matches[7] || "Z";
              let offsetHour = parseInt(matches[8], 10);
              offsetHour = offsetHour >= 0 && offsetHour <= 23 ? offsetHour : 0;
              let offsetMinute = parseInt(matches[9], 10) || 0;
              offsetMinute = offsetMinute >= 0 && offsetMinute <= 59 ? offsetMinute : 0;
          
              if (universalTimeRelation === "-") {
                hour += offsetHour;
                minute += offsetMinute;
              } else if (universalTimeRelation === "+") {
                hour -= offsetHour;
                minute -= offsetMinute;
              }
          
              return new Date(Date.UTC(year, month, day, hour, minute, second)).toISOString();
        } catch (ex) {
			// console.log('ẽ',ex)
            return value;
        }
    }

	/* PDFView verification form */
	onDocumentLoadSuccess = async (pdfObject) => {
		let { numPages } = pdfObject;
        let {
            // PDFFiles,
            PDFFile,
            showAll,
            autoFit,
            scale,
            fullPreview,
            // multipleFilesQueue
        } = this.objMain.state;
        console.log('pdfObject', pdfObject);
        // console.log(PDFFiles);
        // console.log(PDFFile);
        // console.log(PDFFile.initialDone);
        // console.log(tempPDFPassword, keepPDFPassword);
        if (!PDFFile?.initialDone) {
            // let pdfOutline = await pdfObject.getOutline();
            // console.log(pdfOutline);
            let pdfMeta = await pdfObject.getMetadata();
			
			// console.log('hello', pdfMeta)
            PDFFile.info = pdfMeta.info;
            if (pdfMeta.metadata) {
                PDFFile.metadata = pdfMeta.metadata.getAll();
            } else {
                PDFFile.metadata = {};
            }
            // console.log(PDFFile.info);
            // console.log(pdfMeta);
            PDFFile.info.CreateDate = this.DateFromMetadata(PDFFile.info.CreationDate);
            PDFFile.info.ModifyDate = this.DateFromMetadata(PDFFile.info.ModDate || PDFFile.info.CreationDate);

            PDFFile.numPages = numPages;
            PDFFile.currentPage = PDFFile.currentPage || 1;
            let pageSpace = 20;
            let docHeight = 0;
            let pages = {};
            for (let i = 1; i <= numPages; i++) {
                let pageInfo = await pdfObject.getPage(i);
                let opList = await pageInfo.getOperatorList();
                let scan = null;
                let pos = null;
                let pageView = {
                    top: pageInfo.view[0],
                    left: pageInfo.view[1],
                    width: pageInfo.view[2],
                    height: pageInfo.view[3],
                };

                if ([90, 270].includes(pageInfo.rotate)) {
                    pageView.width = pageInfo.view[3];
                    pageView.height = pageInfo.view[2];
                }

                // console.log(pageInfo);
                for (var ii = 0; ii < opList.fnArray.length; ii++) {
                    // paintInlineImageXObject paintImageXObject
                    if (opList.fnArray[ii] === pdfjs.OPS.transform) {
                        pos = opList.argsArray[ii];
                    }
                    if (opList.fnArray[ii] === pdfjs.OPS.paintImageXObject) {
                        // console.log(pdfjs.OPS);
                        // console.log(opList);
                        let img = opList.argsArray[ii];
                        // let imgObject = pageInfo.objs.get(img[0]);
                        // console.log(img);
                        // console.log(imgObject);
                        // for (let mm = ii - 1; mm < 6; mm--) {
                        //     let pos = opList.argsArray[ii - 3];
                        // }
                        if (pos) {
                            if (pos[4] === 0 && pos[5] === 0) {
                                scan = {
                                    key: img[0],
                                    width: img[1],
                                    height: img[2],
                                    index: ii,
                                    position: pos,
                                    // dpi: Math.round(img[1] / (pageView.width / 72)),
                                    dpi: Math.round(img[1] / 8.27),
                                    // key: imgKey,
                                };
                                break;
                            }
                        }
                    }
                }

                if (scan === null) {
                    scan = {
                        key: 'dummyImage',
                        width: 2893,
                        height: 4092,
                        index: 0,
                        position: [2893, 0, 0, 4092, 0, 0],
                        // dpi: Math.round(2893 / (842.04 / 72)),
                        dpi: Math.round(2893 / 8.27),
                        // key: imgKey,
                    };
                }

                pages[i] = {
                    view: pageView,
                    scan: scan,
                };

                docHeight += pageView.height + pageSpace;
            }
            docHeight += pageSpace;
            let currentHeight = pageSpace;
            for (let i in pages) {
                let view = pages[i].view;
                pages[i].view.top = currentHeight;
                pages[i].view.percentage = {
                    top: currentHeight / docHeight * 100,
                    height: view.height / docHeight * 100,
                };
                currentHeight += view.height + pageSpace
            }
            pages[1].view.top = 0;
            pages[1].view.percentage.top = 0;

            PDFFile.pages = pages;
            PDFFile.initialDone = true;

            if (autoFit) {
                // Exclude the width of the PDF information section on the right side (340px) for 742px or more.
                let parentWidth =
                    window.innerWidth > 742
                        ? window.innerWidth - 340
                        : window.innerWidth;
                let parentHeight = window.innerHeight - 120;

                if (!fullPreview) {
                    // Non-fullscreen previews are set to fixed values for height and width.
                    let parentBlock = document.querySelector("#pdf_main_preview.pdf-fragment .pdf-preview-section");
                    parentWidth = parentBlock.offsetWidth - 40;
                    parentHeight = parentBlock.offsetHeight - 60;
                }

                if (showAll) {
                    parentWidth -= 40;
                }

                // console.log(parentWidth, parentHeight);
                // console.log(pages[1]);

                let wScale = parentWidth / pages[1].view.width;
                let hScale = parentHeight / pages[1].view.height;
                // console.log(wScale, hScale);
                console.log(pages[1].view.width, pages[1].view.height);
                console.log('parentWidth');

                scale = wScale;
                if (!showAll && hScale < wScale) {
                    scale = hScale;
                }
                if (scale < 0.1) {
                    scale = 0.1;
                }
                if (showAll) {
                    // console.log(scale);
                    scale = scale * 10;
                    scale = Math.floor(scale);
                    scale = scale / 10;
                    // console.log(scale);
                }
                // scale = parseFloat(scale.toFixed(1));
            }

            if (PDFFile.tryPassword) {
                PDFFile.password = PDFFile.tryPassword;
                delete PDFFile.tryPassword;
            }
            this.objMain.setState({ PDFFile, scale });
        }
        // console.log(PDFFile.numPages)

        this.objMain.setState({ PDFLoadSuccess: true, PDFFile, pagesRendered: 0});
    }

	onDocumentLoadError = (pdfObject) => {
		// console.log(pdfObject);
		let { t } = this.props;

		return (
			<div className="pdf-no-data">
				<span>{t("common:message.pdf-preview.load-pdf-fail")}</span>
			</div>
		);
	}

	increasePreviewSize = (ev) => {
        let { scale = 0.8, PDFFile } = this.objMain.state;
        scale += 0.3;
        if (scale > 10) {
            scale = 10;
        }
        scale = parseFloat(scale.toFixed(2));
        this.objMain.updateState({ scale, autoFit: false });
    }

    decreasePreviewSize = (ev) => {
        let { scale = 0.8,PDFFile } = this.objMain.state;
        scale -= 0.3;
        if (scale < 0.1) {
            scale = 0.1;
        }
        scale = parseFloat(scale.toFixed(2));
        this.objMain.updateState({ scale, autoFit: false });
    }

	ActionController = () => {
		let { PDFFile } = this.objMain.state;

		// if (!controller) {
		//     return null;
		// }

        let PageInfo = "Loading...";
        // if (PDFFile.initialDone) {
        //     PageInfo = `${1}`;
        // }

		// console.log(';diksjfolgsndối', PDFFile.numPages);
		return (
			<div className="control-container">

				{/* <ButtonPDFFileInfo /> */}

				<div className="view-size-controller">
					<div className="btn size-decrease" onClick={this.decreasePreviewSize}>
						<FontAwesomeIcon icon={faMinus} />
					</div>
					<div className="btn size-increase" onClick={this.increasePreviewSize}>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</div>


                <div className="page-index-info">

					<StateFragment state={currentRangePage}>{s => {
						// console.log(s.get())
						return  <span className="info-label">{(s.get() && PDFFile)? <>{s.get()} / {PDFFile.numPages}</> : 'Loading...'}</span>
					}}</StateFragment>
                    {/* <span className="info-label">{PageInfo}</span> */}
                </div>



			</div>
		);
	}
	onRenderSuccess = ()=>{

		this.objMain.setState(prevState => ({
			pagesRendered: prevState.pagesRendered + 1,
		}));
	}
	PDFPages = () => {

		let { PDFFile, showAll = true, scale = 0.8, pagesRendered } = this.objMain.state;
		if (!PDFFile.initialDone) {
			return null;
		}

		const pagesRenderedPlusOne = Math.min(pagesRendered + 1, PDFFile.numPages || 0);
		let Pages = Array.from(
			new Array(pagesRenderedPlusOne < 0 ? 0 : pagesRenderedPlusOne),
			(el, i) => {
			  const isCurrentlyRendering = pagesRenderedPlusOne === i + 1;
			  const isLastPage = PDFFile.numPages === i + 1;
			  const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
			  let active = "";
			  if (i + 1 === parseInt(PDFFile.currentPage)) {
				  active = " active";
			  }
			  if (showAll) {
				  active = " active";
			  }
			  let OcrFound = []
			//   if(this?.objMain?.state?.ocrFound[PDFFile.hash] && this?.objMain.state?.ocrFound[PDFFile.hash][i]) OcrFound.push(this.objMain.generateHTMLOcr(this?.objMain?.state?.ocrFound[PDFFile.hash][i]));
			  if(this?.objMain?.state?.ocrFound[PDFFile.hash] 
				&& this?.objMain.state?.ocrFound[PDFFile.hash][i]) {
				OcrFound = this?.objMain?.state?.ocrFound[PDFFile.hash].reduce((arr,item)=>{
					if(item.page - 1 === i){
						// console.log(item.page - 1, i)
						arr.push(this.objMain.generateHTMLOcr(item));
					}
					return arr;
				}, []);
				// console.log(ocrInPage)
				// OcrFound = ocrInPage.m
				// OcrFound.map(ocrInPage);
				// OcrFound.push(this.generateHTMLOcr(this.state?.objMain?.state?.ocrFound[PDFFile.hash][i]));
			}

			  return (
				<div key={i} className={`pdf-page-panel${active}`}>
					<Page debug={true} 
						 onRenderSuccess={
                          needsCallbackToRenderNextPage ? this.onRenderSuccess : null
                        }
						 key={i} renderMode="canvas" pageNumber={i + 1} renderTextLayer={false} scale={scale} className={`pdf-custom-page scale-${scale}${active}`}>
							        {OcrFound}
						 </Page>
				</div>
			  );
			},
		  );

		return Pages;
	}

	prevFile() {
		let { PDFFile, PDFFiles, formData, originalFormData,document_type_by_key } = this.objMain.state;
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)

		if (file_index > 0) file_index -= 1
		PDFFile = PDFFiles[Object.keys(PDFFiles)[file_index]]

		formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData))
		if (formData.doc_title === '') formData.doc_title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));
		if(document_type_by_key[PDFFile.hash]){
			formData.document_type = document_type_by_key[PDFFile.hash];
		}
		let {requiredField, suggestionData} = this.objMain.state;
		formData.invoice_number = suggestionData[PDFFile.hash]?.invoice_number?.registrated_number;
		PDFFile.formData = formData;
		requiredField['received_date'] = false;
		requiredField['amount'] = false;
		requiredField['counter_party_name'] = false;
		document.getElementById('received_date')?.setAttribute('style',  "")
		document.getElementById('amount')?.setAttribute('style',  "")
		document.getElementById('counter_party_name')?.setAttribute('style',  "")
		this.objMain.setState({requiredField});
		this.objMain.updateState({ PDFFile, formData,rerenderPopper: true }, ()=>{

			if(formData?.preservation_require == 1){
				document.getElementById('received_date')?.removeAttribute('required');
				document.getElementById('amount')?.removeAttribute('required');
				document.getElementById('counter_party_name')?.removeAttribute('required');
				document.getElementById('received_date')?.checkValidity();
				document.getElementById('amount')?.checkValidity();
				document.getElementById('counter_party_name')?.checkValidity();
			}
			this.objMain.setState({rerenderPopper: false})
		})
	}

	nextFile() {
		let { PDFFile, PDFFiles, formData, originalFormData,document_type_by_key, requiredField, suggestionData,} = this.objMain.state;
		
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		if (file_index < Object.keys(PDFFiles).length) file_index += 1;
		PDFFile = PDFFiles[Object.keys(PDFFiles)[file_index]]
		// console.log(PDFFile.formData || originalFormData)
		formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData, replacerFunc()))
		if (formData.doc_title === '') formData.doc_title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));
		formData.invoice_number = suggestionData[PDFFile.hash]?.invoice_number?.registrated_number || '';
		console.log(formData.preservation_require);
		// console.log(formData.invoice_number, suggestionData)
		if(document_type_by_key[PDFFile.hash]){
			formData.document_type = document_type_by_key[PDFFile.hash];
		}
		PDFFile.formData = formData;
		const PDFFileState = PDFFile;
		// console.log('PDFFile', PDFFile)
		if(PDFFiles[PDFFile.hash].password) {
			PDFFile.password = PDFFiles[PDFFile.hash].password;
			PDFFileState.password = PDFFiles[PDFFile.hash].password;
		}
		this.objMain.setState({PDFFile: PDFFileState, selectedFile: PDFFile.hash, formData, requiredField, rerenderPopper: true}, ()=>{
			let { requiredField } = this.objMain.state;
				requiredField["received_date"] = false;
				requiredField["amount"] = false;
				requiredField["counter_party_name"] = false;
				if ([1, "1"].includes(this.objMain.state.formData.preservation_require)) {
					requiredField["received_date"] = true;
					requiredField["amount"] = true;
					requiredField["counter_party_name"] = true;
					this.objMain.setState({isInvalid: {
						received_date: false,
						counter_party_name: false,
						amount: false
					}})
				}else{
					this.objMain.setState({isInvalid: {
						received_date: !formData?.received_date,
						counter_party_name: !formData?.counter_party_name,
						amount: !formData?.amount
					}})
				}
				this.objMain.setState({ requiredField, rerenderPopper: false});

					if (![1, "1"].includes(this.objMain.state.formData.preservation_require)) {
						document.getElementById('received_date')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
						document.getElementById('amount')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
						document.getElementById('counter_party_name')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
					}
					if(formData?.preservation_require == 1){
						document.getElementById('received_date')?.removeAttribute('required');
						document.getElementById('amount')?.removeAttribute('required');
						document.getElementById('counter_party_name')?.removeAttribute('required');
						document.getElementById('received_date')?.checkValidity();
						document.getElementById('amount')?.checkValidity();
						document.getElementById('counter_party_name')?.checkValidity();
					}
		})
		// this.objMain.updateState({PDFFile, formData})
	}

	PDFBtns() {
		let { t } = this.props;
		let { PDFFile, PDFFiles } = this.objMain.state;
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		let back_disabled_btn = false, next_disabled_btn = false, disable_class = ""

		if (file_index === 0) back_disabled_btn = true;
		if (file_index === Object.keys(PDFFiles).length - 1) {
			next_disabled_btn = true;
			disable_class = "-disable"
		}

		if (Object.keys(PDFFiles).length == 1){
			return <div className="page-action-bottom-relative">
					{file_index === 0 ? (
						<div className="wrapper-button-cancel">
							<MuiButton
								variant="contained"
								color="info"
								size='large'
								startIcon={<ChevronLeftIcon />}
								onClick={(ev) => {
									// this
									return this.objMain.prevPageStep(ev, this.constructor)
								}}>
								<span className="btn-label">{t('common:documents.general.back')}</span>
							</MuiButton>
						</div>
					) : (
						<div className="wrapper-button-cancel">
							<MuiButton
								disabled={back_disabled_btn}
								variant="contained"
								color="info"
								size='large'
								startIcon={<ChevronLeftIcon />}
								onClick={(ev) => this.prevFile()}>
								<span className="btn-label">{t('common:documents.general.back')}</span>
							</MuiButton>
						</div>
					)}
					<div className="wrapper-button-submit">
						<MuiButton
							variant="contained"
							type="submit"
							size='large'
							color="primary"
							endIcon={<ChevronRightIcon />}
							onClick={(ev) =>{
								let {requiredField} = this.objMain.state;

								// console.log(this.objMain.state.formData?.preservation_require == 1)
								let invField = {}
								if(this.objMain.state.formData?.preservation_require == 1){
									document.getElementById('received_date')?.removeAttribute('required');
									document.getElementById('amount')?.removeAttribute('required');
									document.getElementById('counter_party_name')?.removeAttribute('required');
									document.getElementById('received_date')?.checkValidity();
									document.getElementById('amount')?.checkValidity();
									document.getElementById('counter_party_name')?.checkValidity();
									invField = {received_date: false,
										amount: false,
										counter_party_name: false,}
								}
								requiredField['received_date'] = false;
								requiredField['amount'] = false;
								requiredField['counter_party_name'] = false;
								document.getElementById('received_date')?.setAttribute('style',  "")
								document.getElementById('amount')?.setAttribute('style',  "")
								document.getElementById('counter_party_name')?.setAttribute('style',  "")
								this.objMain.setState({requiredField})
								this.objMain.formSubmitHandler({target: document.getElementById('form_' + this.name), stopPropagation: ev.stopPropagation, preventDefault: ev.preventDefault}, this, this.formSubmitCallback)
							}}>
							
							<span className="btn-label">{t('common:documents.general.next')}</span>
						</MuiButton>
					</div>
				</div>
		}



		return (
			<div className="page-action-bottom-relative">
				{file_index === 0 ? (
					<div className="wrapper-button-cancel">
						<MuiButton
							variant="contained"
							color="info"
							size='large'
							startIcon={<ChevronLeftIcon />}
							onClick={(ev) => {
								// this

								let {requiredField} = this.objMain.state;
								requiredField['received_date'] = false;
								requiredField['amount'] = false;
								requiredField['counter_party_name'] = false;
								document.getElementById('received_date')?.setAttribute('style',  "")
								document.getElementById('amount')?.setAttribute('style',  "")
								document.getElementById('counter_party_name')?.setAttribute('style',  "")
								this.objMain.setState({requiredField});
								return this.objMain.prevPageStep(ev, this.constructor)
							}}>
							<span className="btn-label">{t('common:documents.general.back')}</span>
						</MuiButton>
					</div>
				) : (
					<div className="wrapper-button-cancel">
						<MuiButton
							disabled={back_disabled_btn}
							variant="contained"
							color="info"
							size='large'
							startIcon={<ChevronLeftIcon />}
							onClick={(ev) => this.prevFile()}>
							<span className="btn-label">{t('common:documents.general.back')}</span>
						</MuiButton>
					</div>
				)}

				{file_index === Object.keys(PDFFiles).length - 1 ? (
					<div className="wrapper-button-submit">
						<MuiButton
							variant="contained"
							type="submit"
							color="primary"
							size='large'
							endIcon={<ChevronRightIcon />}
							onClick={(ev) => 
								{ 
									let {requiredField} = this.objMain.state;
									requiredField['received_date'] = false;
									requiredField['amount'] = false;
									requiredField['counter_party_name'] = false;
									document.getElementById('received_date')?.setAttribute('style',  "")
									document.getElementById('amount')?.setAttribute('style',  "")
									document.getElementById('counter_party_name')?.setAttribute('style',  "")
									this.objMain.setState({requiredField})
									this.objMain.formSubmitHandler({target: document.getElementById('form_' + this.name), stopPropagation: ev.stopPropagation, preventDefault: ev.preventDefault}, this, this.formSubmitCallback)
								}}>
							
							<span className="btn-label">{t('common:documents.general.next')}</span>
						</MuiButton>
					</div>
				) : (
					<div className={`wrapper-button-next-file${disable_class}`}>
						<MuiButton
							disabled={next_disabled_btn}
							variant="contained"
							size='large'
							endIcon={<ChevronRightIcon />}
							onClick={(ev) => {
								// console.log(this.objMain.state.formData)
									if(this.objMain.state.formData?.preservation_require == 1){
										document.getElementById('received_date')?.removeAttribute('required');
										document.getElementById('amount')?.removeAttribute('required');
										document.getElementById('counter_party_name')?.removeAttribute('required');
										document.getElementById('received_date')?.checkValidity();
										document.getElementById('amount')?.checkValidity();
										document.getElementById('counter_party_name')?.checkValidity();
									}
									this.objMain.formSubmitHandler({target: document.getElementById('form_' + this.name), 
									stopPropagation: ev.stopPropagation, 
									preventDefault: ev.preventDefault}, this,()=>{
										// console.log(this.customValidity(),this.customValidity(ev))
										let {requiredField, formData} =this.objMain.state;
										requiredField['received_date'] = false;
										requiredField['amount'] = false;
										requiredField['counter_party_name'] = false;
										document.getElementById('received_date')?.setAttribute('style',  "")
										document.getElementById('amount')?.setAttribute('style',  "")
										document.getElementById('counter_party_name')?.setAttribute('style',  "")

										this.objMain.setState({requiredField});
										if (this.customValidity(ev)) {
											this.nextFile()
										}
									});
								}}>
							<span className="btn-label">{t('common:documents.general.next')}</span>
						</MuiButton>
					</div>
				)}




			</div>
		)

	}

	PDFList = () => {

		let { t } = this.props;

		let { PDFFiles, PDFFile, selectedFile } = this.objMain.state;

		let PDFList = Object.keys(PDFFiles).map((key, index) => {
			let classlist = ["step-child"];

			// if (key === PDFFile.hash) {
			// 	classlist.push("active");
			// }

			let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
			// console.log(index, file_index)
			if (index < file_index) {
				classlist.push("pass");
			} else if (index > file_index) {
				classlist.push("disabled");
			} else {
				classlist.push("active");
			}

			return (
				<li key={key} onClick={(ev) => {
					if (index < file_index) {
						this.prevFile();
					} else if (index > file_index) {
						this.nextFile();
					}

				}} className={classlist.join(" ")}>
					<div className="step-circle">
						<div className="step-circle-dot"></div>
						<div className="step-access-ramp"></div>
					</div>
					<span className="step-label">{t(PDFFiles[key].name)}</span>
				</li >
			);
		});

		return (
			<ul className="step-custom step-vertical">
				{PDFList}
			</ul>
		);
	}

	InputPasswordForm = () => {
		let { t } = this.props;
		let {PDFFile}= this.objMain.state;

		return (
			<form name="pdf-password-form">
				<Form.Row>
					<Form.Group as={Col} controlId="password" className={this.incorrectPassword}>
						<Form.Label style={{whiteSpace: 'pre-line'}}>{t('common:documents.general.pdf-password', {file_name: PDFFile.name})}</Form.Label>
						<Form.Control
							name='pdf-password'
							type='password'
							placeholder={t('common:documents.general.pdf-password', {file_name: PDFFile.name})}
							onChange={(ev) => {
								this.objMain.updateState({ tempPDFPassword: ev.target.value });
							}}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.incorrect-password')}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
			</form>
		);
	}

	modalConfirmDelete = (confirm) => {
		let { modalProps } = this.objMain.state;
		let { selectedFile, PDFFiles } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		if(confirm){
			// if (!PDFFile.password) {
				delete PDFFiles[selectedFile];
				selectedFile = null;
				this.objMain.updateState({modalConfirmDeleteShow: false})
				if (Object.keys(PDFFiles).length > 0) {
					if(file_index - 1 < 0){
						selectedFile = Object.keys(PDFFiles)[file_index];
						PDFFile = PDFFiles[selectedFile];
						this.objMain.setState({ selectedFile,  PDFFiles, PDFFile }, ()=>{
							// console.log(file_index, PDFFile, Object.keys(PDFFiles).length)
							this.objMain.prevPageStep({}, this.constructor);
						});
						
					} else {
						selectedFile = Object.keys(PDFFiles)[file_index - 1];
						PDFFile = PDFFiles[selectedFile];
						if(PDFFile){
							this.objMain.setState({ selectedFile,  PDFFiles, PDFFile }, ()=>{
								// console.log(file_index, PDFFile, Object.keys(PDFFiles).length)
								if(file_index === Object.keys(PDFFiles).length) this.prevFile();
								else this.nextFile();
							});
						} else {
							let keysPDF = Object.keys(PDFFiles);
							selectedFile = keysPDF[keysPDF.length - 1];
							PDFFile = PDFFiles[selectedFile];
							this.objMain.setState({ selectedFile,  PDFFiles, PDFFile }, ()=>{
								// console.log(file_index, PDFFile, Object.keys(PDFFiles).length)
								this.objMain.nextPageStep({}, this.constructor);
							});
						}

					}
				}
				// if (file_index < Object.keys(PDFFiles).length) file_index += 1;
				// let PDFFile2 = PDFFiles[Object.keys(PDFFiles)[file_index]];
			// }
		}else{
			this.objMain.updateState({modalConfirmDeleteShow: false})
			modalProps = {
				"show": true,
				"centered": true,
			}
			this.objMain.updateState({ modalProps });
		}
	}

	onPasswordHandler = (fnCallback, reason) => {

		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps, onExitedModal } = this.objMain.state;
		let { selectedFile, PDFFiles, tempPDFPassword, keepPDFPassword } = this.objMain.state;
		let PDFFile = PDFFiles[selectedFile];
		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			// "dialogClassName": "modal-full",
			"centered": true,
		}
		// 20210706

		// console.log('PDFFile.password', reason);
		// let { PDFFile,} = this.objMain.state;
		// console.log(tempPDFPassword, keepPDFPassword);
		if(reason === 2){}

		
		onExitedModal = () => {
			this.objMain.updateState({modalConfirmDeleteShow: true})
		}
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		// console.log(selectedFile, PDFFile);
		if (PDFFile.password && reason !== 2) {
			fnCallback(PDFFile.password);
		} else if (tempPDFPassword && reason !== 2) {
			fnCallback(tempPDFPassword);
			PDFFiles[selectedFile].password = tempPDFPassword;
			this.objMain.updateState({ keepPDFPassword: tempPDFPassword });
			this.objMain.updateState({ tempPDFPassword: null });
			this.objMain.updateState({ PDFFiles });
			modalProps.show = false;
		} else {
			modalTitle = "common:documents.general.password-required";
			ModalAction = [];
			ModalBody = this.InputPasswordForm;
			ModalAction.push(
				<Button key="ok" sx={{ ml: 1 }} variant="primary" onClick={(ev) => {
					this.incorrectPassword = "was-validated";
					fnCallback(tempPDFPassword);
					this.objMain.modalToggle(false);
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			);

			keepPDFPassword = null;

			this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps, onExitedModal, keepPDFPassword });
		}

		// var password = prompt('Enter the password to open this PDF file.');
		// let aa = passwordCallback("190925316195");
		// console.log(aa);
		// console.log(passwordCallback);
		// console.log(typeof passwordCallback);
	}

	PDFView() {
		let { PDFFile } = this.objMain.state;

		return (
			<div key="main-preview" className={`pdf-fragment pdf-full-preview`}>
				<div className={`pdf-preview-section`}>
					<div className="pdf-preview-panel" ref={this.objMain.containerRef}
                        onMouseUp={this.objMain.mouseUpHandle.bind(this.objMain)}
                        onMouseMove={this.objMain.mouseMoveHandle.bind(this.objMain)} 
                        onMouseDown={this.objMain.mouseDownHandle.bind(this.objMain)} >
						
						<DocumentWithObserver file={PDFFile.file}
							observerRef={this.objMain.containerRef}
							currentRangePage={currentRangePage}
							showLoading={this.objMain.state.showLoadingPDFLoad}
							setCurrentPage={(page)=> currentRangePage.set(`${page}`)}
							options={{
								cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
								cMapPacked: true,
							}}
							onPassword={this.onPasswordHandler}
							onLoadSuccess={this.onDocumentLoadSuccess}
							onLoadError={this.onDocumentLoadError}>
							{this.PDFPages()}</DocumentWithObserver>
						{/* <Document file={PDFFile.file}
							options={{
								cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
								cMapPacked: true,
							}}
							onLoadSuccess={this.onDocumentLoadSuccess}
							onLoadError={this.onDocumentLoadError}
						>
							{this.PDFPages()}
						</Document> */}

					</div>

					<div className="pdf-controller-panel">
						{this.ActionController()}
					</div>
				</div>
			</div>

		);
	}

	/* PDFView verification form */

	checkTitle = () => {

		let { PDFFile } = this.objMain.state;
		let { formData } = this;
		let title = PDFFile?.name?.slice(0, PDFFile?.name?.lastIndexOf('.'))
		console.log(PDFFile, title, formData.doc_title)
		// if change another file
		if (formData?.doc_title === "" || !formData?.doc_title || (formData?.doc_title === 'common:documents.document-info.doc-title' && title)) {
			formData.doc_title = title;
			this.objMain.updateState({ formData });
		}
	}
	CounterPartyList = (props) => {
		let rowList = useMemo(() => {
			let {counterPartyNameList} = props;
			let row = [];
			for (let i = 0; i < counterPartyNameList.length; i++) {
				let counterPartyName = counterPartyNameList[i];
				row.push(
					<option key={`${counterPartyName}-${i}`} value={counterPartyName} />
				);
			}
			return row;
		}, [props.counterPartyNameList])
		
		return (
			<datalist id="counter-party">
				{rowList}
			</datalist>
		);
	}

	ClearOnSelect = (e) => {
		setTimeout(() => {
			const lstDataList = document.querySelectorAll('datalist');
			lstDataList.forEach((item) => {
				for (var i = item.children.length - 1; i >= 0; i--) {
					var childElement = item.children[i];
					if (item.children[i].value === e.target.value) {
						item.removeChild(childElement);
					}
				}
			})}, 100)
	}
	onChangeDocType(ev = null, type, objMain){
		// console.log(ev, type)
		let { document_type_by_key, PDFFile, formData } = objMain.state;
		if(document_type_by_key[PDFFile.hash]){
			document_type_by_key[PDFFile.hash] = formData.document_type;
			objMain.setState({document_type_by_key})
		}
	}
	FileInfoForm = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;

		let { creationType, PDFFile, isSuggestionLoading, PDFFiles, invoiceNotExist } = this.objMain.state;
		let { formData, CounterPartyList } = this;


		let required = true;
		if (creationType === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		if(file_index == -1) file_index = 0;
		let  {fieldApplySuggestion, suggestionData} = this.objMain.state
		const fullWidthToHalfWidth = (fullWidthText) =>  {
			return fullWidthText.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
				return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
			});
		};

		return (
			<Form
				onClick={()=>{
					fieldApplySuggestion['invoice_number'] = null
					this.objMain.setState({fieldApplySuggestion});
				}}
				// validated={true}
				id={"form_" + this.name}
				onSubmit={this.formPrepareBeforeSubmit}
				className="full-form"
			>	
				
				<div className="layout-right-title form-header" >
					<h2>{this.headerInfo}</h2>
				</div>

				<Form.Row>
					{FieldControl({ name: "document_type", as: "select", xs: 12, md: 4, children: this.DocumentTypeItems(), change: ()=>this.onChangeDocType(null, null, this.objMain) })}
					{FieldControl({ name: "preservation_require", as: "select", xs: 12, md: 4, children: this.PreservationRequireItems(), change: (ev) =>  this.customValidity(ev, 'preservation_require') })}
				</Form.Row>

				<Form.Row>
					{FieldControl({  name: "doc_title", xs: 12, md: 12, required, validated: "true", change: this.customValidity })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ 
						name: "received_date", 
						onFocus:(e)=>{
							let {hide_suggestion_field,fieldApplySuggestion} = this.objMain.state;
							fieldApplySuggestion.received_date = e.target;
							if(hide_suggestion_field[PDFFile?.hash]){
								hide_suggestion_field[PDFFile?.hash].received_date = false;
								// console.log('run_here')
								this.objMain.setState({fieldApplySuggestion, hide_suggestion_field});
							}
						}, 
						onClick: (e)=>{
							e.stopPropagation();
						},
						type: "date", 
						xs: 12,
						md: 6, 
						required: !this.objMain.state.requiredField['received_date'], validate: "true", 
						change: (e)=>{
							return this.customValidity(e)
						},
						onKeyDown: (e)=>{
							let {hide_suggestion_field} = this.objMain.state;
							if(hide_suggestion_field[PDFFile?.hash]){
								hide_suggestion_field[PDFFile?.hash].received_date = true;
								this.objMain.setState({hide_suggestion_field});
							}
						}})}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "counter_party_name",
						onClick: (e)=>{
							e.stopPropagation();
							// this.ClearOnSelect(e);
						}, 
						onFocus:(e)=>{
							let {hide_suggestion_field,fieldApplySuggestion} = this.objMain.state;
							fieldApplySuggestion.counter_party_name = e.target;
							if(hide_suggestion_field[PDFFile?.hash]){
								hide_suggestion_field[PDFFile?.hash].counter_party_name = false;
								// console.log('run_here')
								this.objMain.setState({fieldApplySuggestion, hide_suggestion_field});
							}
							// this.ClearOnSelect(e);
						}, 
						list: "counter-party", 
						autoComplete: "off",
						xs: 12, md: 6, 
						required: !this.objMain.state.requiredField['counter_party_name'], 
						validate: "true", 
						change: this.customValidity,
						// onInput: (e) => {
						// 	let options = this.refDataList?.current?.childNodes;

						// 	for(var i = 0; i < options.length; i++) {
						// 		console.log(options[i].value, e.target.value)
						// 		if(options[i].value === e.target.value) {
						// 		  // An item was selected from the list!
						// 		  // yourCallbackHere()
						// 		  console.log(this.refDataList.current)
						// 		//   alert('item selected: ' + val);
						// 		this.refDataList.current.style.display = 'none';
						// 		  break;
						// 		}
						// 	}
						// 	// this.ClearOnSelect(e);
						// },
						onKeyDown: (e)=>{
							let {hide_suggestion_field} = this.objMain.state;
							if(hide_suggestion_field[PDFFile?.hash]){
								hide_suggestion_field[PDFFile?.hash].counter_party_name = true;
								this.objMain.setState({hide_suggestion_field});
							}
						}})}
		

					<datalist ref={this.refDataList} id="counter-party">
						{this.objMain.state.counterPartyNameList?.map((counterPartyName, i) => <option key={`${counterPartyName}-${i}`} value={counterPartyName} />)}
					</datalist>
					{/* <CounterPartyList counterPartyNameList={this.objMain.state.counterPartyNameList} /> */}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "amount",
						onClick: (e)=>{
							e.stopPropagation();
						}, 
						onFocus:(e)=>{
							setTimeout(()=>{

								let {hide_suggestion_field,fieldApplySuggestion} = this.objMain.state;
								fieldApplySuggestion.amount = e.target;
								if(hide_suggestion_field[PDFFile?.hash]){
									hide_suggestion_field[PDFFile?.hash].amount = false;
									// console.log('run_here')
									this.objMain.setState({fieldApplySuggestion, hide_suggestion_field});
								}
								// this.objMain.setState({fieldApplySuggestion: {invoice_number: null,counter_party_name:null, amount: e.target, received_date: null}})
							}, 300)
						},  
						type: "number", 
						step: "0.01", 
						xs: 8, 
						md: 6, 
						required: !this.objMain.state.requiredField['amount'], 
						validate: "true", 
						change: this.customValidity,
						onKeyDown: (e)=>{
							let {hide_suggestion_field} = this.objMain.state;
							if(hide_suggestion_field[PDFFile?.hash]){
								hide_suggestion_field[PDFFile?.hash].amount = true;
								this.objMain.setState({hide_suggestion_field});
							}
						}})}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 3, children: this.CurrencyItems() })}
				</Form.Row>

				<Form.Row className="invoice_number_form_verification">
					{FieldControl({ name: "invoice_number", 
					disabled: isSuggestionLoading[PDFFile.hash], 
					xs: 12, md: 6, 
					change: this.customValidity,
					onBlur: (e)=>{
						let resultString = e.target.value;
						if(e.target.value){
							const sanitizedString = fullWidthToHalfWidth(e.target.value).replace(/[^\w０-９]/g, '');
							resultString = sanitizedString.charAt(0) !== 'T' ? 'T' + sanitizedString : sanitizedString;
							formData.invoice_number = resultString;
							this.objMain.setState({formData})
						}
						if(resultString.length > 10){
							isSuggestionLoading[PDFFile.hash] = true;
							this.objMain.setState({isSuggestionLoading});
							searchBusinessNumber(
								resultString
							).then(r=>{
								let {suggestionData} = this.objMain.state;
								let data = r.data.payload[0];
								suggestionData[PDFFile?.hash]['invoice_number'] = {
									exist: data.exist,
									name: data.name || "",
									registration_date: data.registration_date,
									address: data.address,
									registrated_number: data?.registrated_number || data?.invoice_number
								};
								isSuggestionLoading[PDFFile.hash] = false;
								this.objMain.setState({suggestionData, isSuggestionLoading});
							}).catch(e=>{
								let {fieldApplySuggestion, suggestionData} = this.objMain.state;
								if(suggestionData[PDFFile?.hash]){
									suggestionData[PDFFile?.hash]['invoice_number'] = {};
								}
								isSuggestionLoading[PDFFile.hash] = false;
								this.objMain.setState({fieldApplySuggestion, suggestionData, isSuggestionLoading});
							})
						}
						
					}})}
					<span className={!suggestionData[PDFFile.hash]?.invoice_number?.exist ? `activeFlag_inactive`:`activeFlag_active`} onMouseOver={(e)=>{
							if(!suggestionData[PDFFile.hash]?.invoice_number?.exist) return;
							fieldApplySuggestion['invoice_number'] =  e.target;
							this.objMain.setState({fieldApplySuggestion});
						}}>
						{isSuggestionLoading[PDFFile.hash] ? <CircularProgress /> :
						!suggestionData[PDFFile.hash]?.invoice_number?.exist ? t(`common:business.number.status.inactive`):
						   t(`common:business.number.status.active`)}
					</span>
					{/* <CounterPartyList /> */}
				</Form.Row>
				{/* for verification */}
				{/* <Form.Row> */}
					{/* {FieldControl({ name: "received_date", type: "date", xs: 12, md: 4, required, validate: "true", change: this.customValidity })} */}
					{/* {FieldControl({ name: "deal_date", type: "date", xs: 12, md: 4, required, validate: "true" })} */}
					{/* {FieldControl({ name: "counter_party_name", xs: 12, md: 8, required, validate: "true", change: this.customValidity })} */}
				{/* </Form.Row> */}

				<Form.Row>
					{FieldControl({ name: "product", xs: 12, md: 12 })}
					{FieldControl({ name: "reference", xs: 12, md: 12 })}
				</Form.Row>

				<Form.Row>
					{FieldControl({
						name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
						className: "filebox-shortcut-path",
						prepend: () => {
							return (
								<InputGroup.Prepend className="mb-3">
									<MuiButton variant="outlined" sx={{ height: 40, mr: -1 }} className="input-group-child" onClick={this.showFileboxPathSelection}>
										<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
									</MuiButton>
								</InputGroup.Prepend>
							)
						}
					})}
				</Form.Row>

				<div className="attributes-wrapper">
					{this.renderAttributes()}
				</div>
				<Form.Row className="attributes-wrapper">

					<Form.Group as={Col} controlId="attributes">
						<Button variant="outline-primary" onClick={this.addAttribute} className="btn-custom attributes" >
							<FontAwesomeIcon icon={faPlus} />
							<span className="label">{t('common:documents.document-info.attributes')}</span>
						</Button>
					</Form.Group>

				</Form.Row>

				{this.PDFBtns()}
			</Form>

		);
	}

	PDFFileList = () => {
		let { PDFFiles, selectedFile } = this.objMain.state;
		let fileKeys = Object.keys(PDFFiles);

		let fileList = fileKeys.map((key) => {
			let PDFFile = PDFFiles[key];
			let classlist = ["file-item",];
			if (key === selectedFile) {
				classlist.push("active");
			}

			let iconProps = {};
			if (!PDFFile.hasOwnProperty("validity")) {
				iconProps.className = "file-form-validate";
				iconProps.icon = faQuestion;
			} else if (PDFFile.validity === true) {
				iconProps.className = "file-form-validate-success";
				iconProps.icon = faCheck;
			} else {
				iconProps.className = "file-form-validate-fail";
				iconProps.icon = faExclamationTriangle;
			}

			return (
				<li key={key} data-file={key} className={classlist.join(" ")}>
					<span className="file-name" title={PDFFile.name} onClick={(ev) => {
						let { PDFFiles, PDFFile, formData, originalFormData } = this.objMain.state;
						// console.log(key);
						// console.log(PDFFile.hash);
						// console.log(formData);
						PDFFiles[PDFFile.hash].formData = JSON.parse(JSON.stringify(formData));
						PDFFile = PDFFiles[key];
						formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData));
						selectedFile = key;
						this.objMain.updateState({ PDFFiles, PDFFile, formData, selectedFile });
					}}>{PDFFile.name}</span>
					<FontAwesomeIcon {...iconProps} />
				</li >
			);
		});

		return fileList;
	}

	formPrepareBeforeSubmit = (ev) => {
		this.customValidity(ev);
		this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback);
	}

	Menu = (props) => {
		return (
			<div className="main contrainer-wrapper">
				<h1>jflsdkfjlsdkfj</h1>
			</div>
		);
	};
	resizerTrigger(e){
		return  this.resize(e);
	}
	onClickSelectInFields(item, index, type, objMain){
		let {formData, hide_suggestion_field} = objMain.state;
		// console.log(item, index, type);
		formData[type] = item;
		// console.log(hide_suggestion_field,hide_suggestion_field[type], type);
		// objMain.setState({hide_suggestion_field})
		objMain.setState({
			formData,
		}, ()=>{ 
			let { requiredField,isInvalid, PDFFile } = objMain.state;
				requiredField[type] = true;
				isInvalid[type] = false;
			objMain.setState({ 
				requiredField,
				isInvalid}, ()=>{

				document.getElementById(type)?.checkValidity();
			});
			// document.getElementById(type).blur();
			hide_suggestion_field[PDFFile?.hash][type] = true;
			objMain.setState({hide_suggestion_field})
			document.getElementById(type)?.setAttribute('style',  "")
		});

	}
	render() {
		let { t } = this.props;
		let { FieldControl } = this.objMain;

		let { creationType, PDFFile, suggestionCommon,PDFFiles, modalConfirmDeleteShow } = this.objMain.state;
		let { formData, CounterPartyList } = this;


		let required = true;
		if (creationType === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)
		if(file_index == -1) file_index = 0;
		let  {fieldApplySuggestion,rerenderPopper, suggestionData, menuContentVerification, hide_suggestion_field} = this.objMain.state;
		// console.log({rerenderPopper, fieldApplySuggestion, hide_suggestion_field:[PDFFile?.hash], suggestionData:suggestionData[PDFFile?.hash]})
		return (
			<>
			{rerenderPopper ? null :
				Object.keys(fieldApplySuggestion || {}).map(a=> <BaseSuggestionPopover 
					key={a}
					className="popper_suggest_fields_ocr"
					anchorEl={fieldApplySuggestion[a]} 
					open={a !== 'invoice_number' ? (!!hide_suggestion_field[PDFFile?.hash] && !hide_suggestion_field[PDFFile?.hash][a]) : !!fieldApplySuggestion[a]}
					onClickCb={this.onClickSelectInFields}
					type={a}
					offsetTop={-8}
					offsetLeft={10}
					fileKey={PDFFile?.hash}
					objMain={this.objMain}
					menuContent={menuContentVerification && menuContentVerification[a] && suggestionData[PDFFile.hash] ? 
						menuContentVerification[a]:null}
					items={[suggestionData && suggestionData[PDFFile?.hash] && suggestionData[PDFFile?.hash][a]]}
					handleClose={()=> this.objMain.setState({fieldApplySuggestion: {...fieldApplySuggestion, [a]: null}})}
				/>)
			}
			
			<div className="select-tab mobile-active">
				<div key={1} className={this.objMain.state.activeTab != 2 ? 'select-tab-item active' : 'select-tab-item'} style={{width: '50%', paddingTop: '10px', paddingBottom: '10px'}} onClick={() => {this.objMain.setState({activeTab: 1})}}>
					<span className="title">{t('common:documents.general.basic-info')}</span>
				</div>
				<div key={2} className={this.objMain.state.activeTab == 2 ? 'select-tab-item active' : 'select-tab-item'} style={{width: '50%', paddingTop: '10px', paddingBottom: '10px'}} onClick={() => {this.objMain.setState({activeTab: 2})}}>
					<span className="title">PDF</span>
				</div>
			</div>
			<div className="verification-form mobile-active">
				{this.objMain.state.activeTab == 2 ?
				<div id="resizer_container" className="verification-form-col" style={{width: this.objMain.state.resizerWidth }}>
					<div id="resizer2" onMouseDown={(e) => {
							let resizeContainer = document.getElementById('resizer_container')
							let startX = e.clientX; // horixontal cordinate
							let startY = e.clientY; // vertical cordinate
							let startWidth = parseInt(document.defaultView.getComputedStyle(resizeContainer).width, 10);
							let startHeight = parseInt(document.defaultView.getComputedStyle(resizeContainer).height, 10);   
							const resizerFunc = this.resizerTrigger.bind(this.objMain)
							this.objMain.setState({
								startResize: true,
								startX,
								startY,
								startWidth,
								resizeContainer,
								startHeight});
							document.addEventListener("mousemove", resizerFunc, false);
							document.addEventListener(
								"mouseup",
								() => {
								document.removeEventListener("mousemove", resizerFunc, false);
								this.objMain.setState({
									startResize: false
								})
								// startResize.set(false);
								},
								false
							);
						}}
					></div>
					{this.PDFView()}
				</div> : <div className="verification-form-col" >
					{this.FileInfoForm()}
				</div>}
			</div>
			
			<div className="verification-form desktop-active" 	onClick={()=>{
				let {hide_suggestion_field, fieldApplySuggestion} = this.objMain.state;
					// if(hide_suggestion_field.invoice_number){
						
					// }
					// this.objMain.setState({fieldApplySuggestion: {
					// 	 invoice_number: null,
					// 	 amount: null, 
					// 	 received_date: null,
					// 	 counter_party_name: null
					// }})
				}}>
				<div className="verification-form-col" ref={this.objMain.formRef}>
					{this.FileInfoForm()}
				</div>
				<div id="resizer_container" className="verification-form-col" style={{width: this.objMain.state.resizerWidth }}>
					<div id="resizer2" onMouseDown={(e) => {
							let resizeContainer = document.getElementById('resizer_container')
							let startX = e.clientX; // horixontal cordinate
							let startY = e.clientY; // vertical cordinate
							let startWidth = parseInt(document.defaultView.getComputedStyle(resizeContainer).width, 10);
							let startHeight = parseInt(document.defaultView.getComputedStyle(resizeContainer).height, 10);   
							const resizerFunc = this.resizerTrigger.bind(this.objMain)
							this.objMain.setState({
								startResize: true,
								startX,
								startY,
								startWidth,
								resizeContainer,
								startHeight});
							document.addEventListener("mousemove", resizerFunc, false);
							document.addEventListener(
								"mouseup",
								() => {
								document.removeEventListener("mousemove", resizerFunc, false);
								this.objMain.setState({
									startResize: false
								})
								// startResize.set(false);
								},
								false
							);
						}}
					></div>
					{this.PDFView()}
				</div>
			</div>
			<Modal show={modalConfirmDeleteShow} animation={false} onHide={(ev) => { this.modalConfirmDelete(false) }}  centered={true}>
					<Modal.Header closeButton>
					<Modal.Title>{t('common:document.title.pdf-password-confirm-delete')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{t('common:document.message.pdf-password-confirm-delete')}</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={(ev) => { this.modalConfirmDelete(false) }}>{t('common:auth.general.cancel')}</Button>
					<Button variant="primary" onClick={(ev) => { this.modalConfirmDelete(true) }}>{t('common:auth.general.ok')}</Button>
					</Modal.Footer>
					
				</Modal>
			</>

		);

	}

}
