import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import '../../Assets/css/auth.css';

import { withTranslation } from "react-i18next";
// import Service from '../../../Service';

import {
    Form,
    Col,
    // Button,
    InputGroup,
    // Dropdown,
    // DropdownButton,
} from 'react-bootstrap';
import AuthService from '../../../Service/Auth';
import Loading from '../../Loading';
import Button from "@mui/material/Button";

// import Amplify, { Auth } from "aws-amplify";
// import awsCognito from "../../../Config/Cognito";

// Amplify.configure(awsCognito);

class ResendVerifyEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: props.match.params,
            token: props.match.params.token,
            status: null,
            formValidate: false,
            loading: false,
            email: null,
        };
    }

    componentDidMount() {
        // let { t } = this.props;
        // let { token, status } = this.state;

        // status = t('common:auth.registration.verification.loading');
        document.title = this.props.t("common:auth.registration.verification.title");

        // this.setState({ status });
    }

    onChangeHandler = (event) => {
        // let key = event.target.name;
        let val = event.target.value;
        this.setState({ email: val });
    }

    formSubmitHandler = (ev) => {
        ev.preventDefault();

        let { t } = this.props;
        let { formValidate, email, status } = this.state;
        formValidate = true;
        this.setState({ formValidate, loading: true });
        // console.log(email);

        if (ev.target.checkValidity() === false) {
            ev.stopPropagation();
        } else {
            AuthService.RegisterResendVerifyEmail(email).then(resp => {
                // console.log(resp);
                status = t('common:auth.registration.resend-email.success');
                this.setState({ status, loading: false });
            }).catch(err => {
                //console.log(err.response);
                if (err.response.data.hasOwnProperty("error")) {
                    status = t(`common:${err.response.data.error}`);
                } else {
                    status = t('common:auth.registration.resend-email.fail');
                }
                this.setState({ status, loading: false });
            });
        }
    };

    componentDidUpdate(prevProps) {
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    FormBodyContent = () => {
        let { t } = this.props;
        let { status, formValidate, email, loading } = this.state;

        if (loading) {
            return <Loading />;
        }

        if (status) {
            return (
                <div className="form-container">
                    <div className="fields-container">
                        <p>{status}</p>
                    </div>

                    <div className="form-bottom">
                        <div className="wrapper-button-cancel">
                            <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                        </div>
                        <div className="wrapper-button-submit">

                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="form-container">

                <Form
                    validated={formValidate}
                    id={"form_resend_email"}
                    onSubmit={this.formSubmitHandler}
                    noValidate
                >

                    <div className="fields-container">

                        <div className="fields-container">

                            <Form.Row>
                                <Form.Group as={Col} controlId="email" className="custom-form-outline">
                                    <Form.Label>{t("common:auth.registration.user.email")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1">
                                            <span class="material-symbols-outlined">mail</span>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name='email'
                                            type='email'
                                            placeholder={t("common:auth.registration.user.email")}
                                            value={email || ""}
                                            onChange={this.onChangeHandler}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('common:message.input.required', { field_name: t("common:auth.registration.user.email") })}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>

                        </div>

                    </div>

                    <div className="form-bottom">
                        <div className="wrapper-button-cancel">

                        </div>
                        <div className="wrapper-button-submit">
                            <Button variant="contained" type='submit'>
                                <span>{t('common:auth.registration.resend-email.submit')}</span>
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        );
    }

    render() {

        let { t } = this.props;
        let { FormBodyContent } = this;

        return (
          <div className="auth-contrainer">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <div className="form-wrapper registration" style={{ "width": "600px" }}>

                            <div className="logo-horizontal">
                                <img src={logo} className="App-logo" alt="paperlogic" />
                            </div>

                            <div className="form-header">
                                <div className="header-info">
                                    <h3>{t('common:auth.registration.resend-email.header')}</h3>
                                </div>
                            </div>

                            <FormBodyContent />

                        </div>

                    </div>

                    <div className="footer">
                        <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
                    </div>
                </div>

            </div >
        );
    }

}
export default withTranslation()(ResendVerifyEmail);
