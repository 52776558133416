// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ProfileAvatar from "../../../Layout/ProfileAvatar";
import logo from "../../../Pages/Assets/img/logo_figure.svg";
import { notification as IconNotification } from "../../../Pages/Assets/SVGIcons";
import Service from "../../../Service";
// import { Box, Divider, InputAdornment, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
// import { UserInfoContext } from "../Context/UserInfoContext";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { GetUserInfoNew } from "../../../data-access/api";
import { appLoadingShow, currentLang } from "../../../data-access/app.state";
import i18n from "../../../lang.init";
import Loading from "../../../Pages/Loading";
import { getFoldersTree } from "../../data-access/api";
import { getCompanyFolders } from "../../data-access/company-folders/company-folders.api";
import {
  currentSelectedCompanyFolderState,
  driveNavCompanyTree,
} from "../../data-access/company-folders/company-folders.state";
import {
  breabCrumbState,
  driveDetailLoading,
  driveFileDrawer,
  driveFoldersDetail,
  driveFoldersNav,
  driveLoading,
  folderDetailSearch,
  globalAnchorContextMenu,
  notificationAlertState,
  notificationInfoState,
  showConfirmOnDelete,
  userInfoState,
} from "../../data-access/state";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import DriveConfirmDialog from "../drive-dialogs/drive-confirm-base";
import DriveNav from "../drive-nav/drive-nav";
import DriveNavCompany from "../drive-nav/drive-nav-company";
import DriveGuestNav from "./drive-guest-nav";
/**
 * @param {any} props
 */
// @ts-ignore
function LanguesChange(props) {
  const { t } = useTranslation("common");
  let [settinglangMenu, setState] = useState({ settinglangMenu: null });
  let loading = " data-loading";
  const navigate = useHistory();
  const openlang = Boolean(settinglangMenu?.settinglangMenu);
  const handleClick = (/** @type {{ currentTarget: any; }} */ event) => {
    // @ts-ignore
    setState({ settinglangMenu: event.currentTarget });
  };

  const handleClose = () => {
    // @ts-ignore
    setState({ settinglangMenu: null });
  };

  const setLang = (lang) => {
    // console.log(ev.target.getAttribute("lang"));
    Service.UpdateLanguage(lang || "ja").catch((err) => {
      console.log(err.response);
    });
    userInfoState.set((v) => ({ ...v, language: lang || "ja" }));
    localStorage.setItem(
      "user-info",
      JSON.stringify(userInfoState.get({ noproxy: true }))
    );
    currentLang.set(lang || "ja");
    i18n.changeLanguage(lang || "ja").then(() => {
      handleClose();
    });
  };
  return (
    <div>
      <Button
        id="menu-lang-button"
        aria-controls={openlang ? "menulang" : undefined}
        aria-haspopup="true"
        aria-expanded={openlang ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="">
          <div className="info-context">
            <LanguageIcon fontSize="small" />
            <span className="name">
              {t("common:main.general.setting-lang")}
            </span>
          </div>
        </div>
      </Button>
      <Menu
        id="menulang"
        anchorEl={settinglangMenu?.settinglangMenu || undefined}
        open={openlang}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-lang-button",
        }}
        className={"profile-menu" + loading}
        color="gray"
      >
        <MenuItem onClick={() => setLang("ja")}>
          {t("common:main.general.japanlang")}
        </MenuItem>
        <Divider />
        <MenuItem lang="en" onClick={() => setLang("en")}>
          {t("common:main.general.englang")}
        </MenuItem>
      </Menu>
    </div>
  );
}
function DriveLayoutLeft({ isGuest, isCompany, fromRoot, fromDocumentSelect }) {
  const { t } = useTranslation("common");
  const width = useHookstate(null);
  const startResize = useHookstate(false);
  const navigate = useHistory();
  const driveLoad = useHookstate(driveLoading);
  const { folderId } = useParams();
  // console.log(t('common:menu.filebox'))
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  /**
   * @param {{ x: any; }} e
   */
  function resize(e) {
    const size = `${e.x}px`;
    // @ts-ignore
    if (e.x < 256) {
      width.set("256px");
    } else {
      width.set(size);
    }
  }
  useEffect(() => {
    if (startResize.value) {
      document.addEventListener("mousemove", resize, false);
      document.addEventListener(
        "mouseup",
        () => {
          document.removeEventListener("mousemove", resize, false);
          startResize.set(false);
        },
        false
      );
    }
    return () => {
      document.removeEventListener("mousemove", resize, false);
      document.removeEventListener("mouseup", resize, false);
      startResize.set(false);
    };
  }, [startResize]);

  const folderDetailList = useHookstate(driveFoldersDetail);

  const detailSearch = useHookstate(folderDetailSearch);

  const driveDetailLoad = useHookstate(driveDetailLoading);
  useEffect(() => {
    // console.log("isCompany");
    if (isCompany) {
      const defaultId = breabCrumbState
        .get({ stealth: true })
        .find((a) => a.type === 6)?.id;
      currentSelectedCompanyFolderState.set(defaultId || 0);
      driveDetailLoad.set(true);
      getFoldersTree({
        page_number: 1,
        page_size: 100,
        parent_id: "null",
      }).then((r1) => {
        // console.log(r.data.payload)
        getCompanyFolders({
          page_number: 1,
          page_size: 100,
          drive_folder_id: defaultId,
          search: "",
          order_by: "name asc, updated_date desc, size asc, memo asc",
        })
          .then((r) => {
            // console.log(r.data.payload);
            driveNavCompanyTree.set((v) => [
              {
                ...[r1.data.payload.find((a) => a.type === 6)].map((a) =>
                  mappedDataToTreeView(a, true)
                )[0],
                id: "company",
                parent: 0,
                name: userInfoState.get({ stealth: true })?.tenant_name || "",
                droppable: true,
                draggable: false,
                fileType: "folder",
                notShowDropDownIcon: true,
              },
              ...r.data.payload.map((a) => mappedDataToTreeView(a, true)),
            ]);
            folderDetailList?.data?.merge({
              ["company" ? "company" : "root"]:
                r?.data?.payload?.map(mappedDataToTreeView),
            });
          })
          .finally(() => driveDetailLoad.set(false));
      });

      // getCompanyFolders({
      //   drive_folder_id: defaultId,
      //   ...detailSearch.get({ stealth: true, noproxy: true }),
      // })
      //   .then((r) => {
      //     // console.log(r);

      //   })
      //   .finally(() => driveDetailLoad.set(false));
    }
    // console.log(driveNavCompanyTree.get());
  }, [isCompany]);
  if (
    driveLoad.get() &&
    !window.location.href.includes("/guest/document") &&
    !window.location.href.includes("/shared-folder/delivery")
  ) {
    return (
      <div className="main-body-loading">
        <Loading />
      </div>
    );
  }
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  return (
    <>
     <div className={`toggle-nav-button ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
      <span></span>
      <span></span>
      <span></span>	
    </div>
      <div className={`layout-left is-drive ${isMenuOpen ? "menu-open" : ""}`}
      style={{
        // @ts-ignore
        width: width.get(),
      }}>
      <div
        id="resizer"
        onMouseDown={() => {
          document.addEventListener("mousemove", resize, false);
          document.addEventListener(
            "mouseup",
            () => {
              document.removeEventListener("mousemove", resize, false);
              startResize.set(false);
            },
            false
          );
        }}
      ></div>
      <div
        className="left-fixed-box"
        style={{
          // @ts-ignore
          width: width.get(),
        }}
      >
        {!isCompany ? <div className="left-head-box"></div> : null}
        {!isCompany ? (
          <a
            className="logo-horizontal"
            onClick={
              window.location.href.includes("/shared-folder/delivery")
                ? undefined
                : () => navigate.push("/")
            }
          >
            <div className="logo-wrapper">
              <img src={logo} className="App-logo" alt="paperlogic" />
            </div>
          </a>
        ) : null}

        <div className="menu-container">
          <div className="menu-box-drive">
            {isGuest ? (
              <DriveGuestNav />
            ) : isCompany ? (
              <DriveNavCompany />
            ) : (
              <DriveNav fromDocumentSelect={fromDocumentSelect} />
            )}

            {/* <Menu />
          <HiddenMenu /> */}
          </div>

          {isCompany ? null : (
            <div className="menu-bottom-container">
              <div className="menu-bottom">
                <div className="footerInfo">
                  <LanguesChange />
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:menu.terms-of-service")}
                  </a>
                  {/* <a href="https://paperlogic.co.jp/contact/" */}
                  <a
                    href="https://support.paperlogic.jp/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:menu.contact-us")}
                  </a>
                </div>
                <div className="copylight">
                  <span>© {new Date().getFullYear()} paperlogic co., ltd.</span>
                </div>
                {/* <div className="copylight">
                  <span>{t(`common:menu.version`)}: {localStorage.getItem("current-version")}</span>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
// @ts-ignore
function TenantSwitch({ userInfo }) {
  // let { t } = this.props;
  const navigate = useHistory();
  if (!userInfo) {
    return null;
  }

  if (userInfo.tenants.length === 1) {
    return (
      <div className="icon tenant-switch">
        <span>{userInfo.tenant_name}</span>
      </div>
    );
  }

  let TenantList = [];
  for (let tenant of userInfo.tenants) {
    // console.log(tenant);
    TenantList.push(
      <MenuItem value={tenant.id} key={tenant.id}>
        {tenant.name}
      </MenuItem>
    );
  }

  return (
    <div className="icon tenant-switch">
      <Box py={1} px={2}>
        <Select
          value={userInfo.tenant_id}
          style={{ color: "#007BFF" }}
          onChange={(ev) => {
            appLoadingShow.set(true);
            let tenant_id = parseInt(ev.target.value);
            if('serviceWorker' in navigator){
              navigator.serviceWorker.ready.then((registration) => {
                if(registration?.active){
                    // registration.dispatchEvent
                    registration.active.postMessage(
                        "cache-clear-logout"
                    );
                }
              }); 
            }
            Service.TenantSwitch({ tenant_id })
              .then((resp) => {
                // Service.deleteUserControl()
                //   .then((response) => {
                // window.location.reload();
                const apiPromises = [
                  GetUserInfoNew(),
                ];
                Promise.all(apiPromises)
                .finally(() => {
                  localStorage.removeItem('prev_host_signer_list');
                  localStorage.removeItem('prev_viewer_list');
                  localStorage.removeItem('prev_verification_viewer_list');
                  localStorage.removeItem('prev_use_digital_certificate');
                  appLoadingShow.set(false);
                  navigate.go(0);
                });
                // const user = localStorage.getItem('user-info');
                // if (user){
                // }
                // })
                // .catch((error) => {
                //   //console.log("CATCH", error);
                // });
              })
              .catch((err) => {
                appLoadingShow.set(false);
                //console.log("error", err.response);
              });
          }}
          renderValue={() => userInfo.tenant_name}
          variant={"standard"}
        >
          {TenantList}
        </Select>
      </Box>
    </div>
  );
}
// @ts-ignore
function UserInfoMenu({ userInfo }) {
  const { t } = useTranslation("common");
  let [userInfoMenuAnchorEl, setUserInfoMenuAnchorEl] = useState();
  // let { ProfileAvatar } = this;
  let UserFullname = "";
  let TenantName = "";
  let loading = " data-loading";
  let iconProps = {};

  if (userInfo) {
    UserFullname = `${userInfo.family_name} ${userInfo.first_name}`;
    TenantName = `${userInfo.company_name}`;
    loading = "";
    // iconProps.avatar = UserInfo.profile_image;
    iconProps.avatar = localStorage.getItem("profile_image");
  }
  const open = Boolean(userInfoMenuAnchorEl);
  const handleClick = (
    /** @type {{ currentTarget: import("react").SetStateAction<undefined>; }} */ event
  ) => {
    setUserInfoMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // @ts-ignore
    setUserInfoMenuAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={"profile-menu" + loading + " profile"}
      >
        <div className="profile-icon">
          <ProfileAvatar {...iconProps} />
        </div>
        <div className="profile-info">
          <div className="info-context">
            <span className="name">{UserFullname}</span>
            <span className="tenant">{TenantName}</span>
          </div>
        </div>
      </Button>
      <Menu
        id="menu"
        anchorEl={userInfoMenuAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        className={"profile-menu" + loading}
        color="gray"
      >
        <Link to={"/settings/profile"}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ManageAccountsIcon color="primary" />
            </ListItemIcon>
            {t("common:main.general.user-setting")}
          </MenuItem>
        </Link>
        <Divider />
        <Link to={"/logout"}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LogoutIcon color="primary" />
            </ListItemIcon>
            {t("common:auth.general.logout")}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

export default function DriveLayout({
  children,
  isGuest,
  isCompany,
  fromRoot,
  fromDocumentSelect,
}) {
  // @ts-ignore
  const showAlert = useHookstate(notificationAlertState);
  const showInfoNoti = useHookstate(notificationInfoState);
  const treeData = useHookstate(driveFoldersNav);
  const { t } = useTranslation("common");
  // const [userInfo, setUserInfo] = useState(null);
  const userInfo = useHookstate(userInfoState);

  const confirmDialog = useHookstate(showConfirmOnDelete);
  // useEffect(() => {
  //   Service.GetUserInfo()
  //     .then((resp) => {
  //       // console.log("resp", resp);
  //       // setUserInfo(resp);
  //       userInfo.set(resp);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    showAlert.show.set(false);
  }
  useEffect(() => {
    if (userInfo.value) {
      if (driveFoldersNav.get({ stealth: true }).length > 0) {
        driveFoldersNav.set((v) => {
          const data = v.filter((a) => {
            if (a.type === 6 && userInfo.get({ stealth: true })) {
              a.name = userInfo.get({ stealth: true }).tenant_name;
            }
            return true;
          });

          return data.sort((a, b) => {
            if (a.type === 7) {
              return -1;
            } else if (![1, 2, 3, 4, 5].includes(a.type)) {
              return 0;
            } else {
              return a.type - b.type;
            }
          });
        });
      }
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((r) => {
        // console.log(userInfoState.get({ stealth: true }));

        if (driveFoldersNav.get({ stealth: true }).length < 4) {
          driveLoading.set(true);

          getFoldersTree({
            page_number: 1,
            page_size: 50,
            parent_id: "null",
          }).then((r) => {
            // console.log();
            if (r?.data?.payload?.length > 0) {
              const data = r.data.payload.map(mappedDataToTreeView);
              const completeFolder = data.find((a) => a.type === 5);
              const companyFolder = data.find((a) => a.type === 6);
              if (completeFolder) {
                getFoldersTree({
                  page_number: 1,
                  page_size: 50,
                  parent_id:
                    completeFolder.id === "drive" ? "null" : completeFolder.id,
                }).then((r) => {
                  if (r?.data?.payload?.length > 0) {
                    driveFoldersNav.set((v) => {
                      const newData = [
                        ...v,
                        ...r.data.payload.map(mappedDataToTreeView),
                      ].reduce((arr, item) => {
                        if (!arr.find((b) => b.id == item.id)) {
                          arr.push(item);
                        }
                        return arr;
                      }, []);
                      return newData;
                    });
                  }
                });
              }
              const filterData = data.filter((a) => {
                if (a?.type === 6 && userInfoState.get({ stealth: true })) {
                  a.name = userInfo.get({ stealth: true })?.tenant_name || "";
                }
                return true;
              });

              driveFoldersNav.set([
                {
                  fileType: "folder",
                  id: "drive",
                  parent: 0,
                  name: "common:drive.tree_nav.root_folder",
                  droppable: false,
                  draggable: false,
                  notShowDropDownIcon: true,
                },
                ...filterData.sort((a, b) => {
                  if (a.type === 7) {
                    return -1;
                  } else if ([1, 2, 3, 4, 5].includes(a.type)) {
                    return a.type - b.type;
                  } else {
                    return 0;
                  }
                  // return a.type - b.type
                }),
                {
                  draggable: false,
                  droppable: false,
                  id: "delivery",
                  name: "common:drive.tree_nav.delivery_main",
                  parent: 0,
                  notShowDropDownIcon: true,
                  fileType: "email",
                },
                {
                  draggable: false,
                  droppable: false,
                  id: "delivery/list",
                  name: "common:drive.tree_nav.file_delivery",
                  parent: "delivery",
                  notShowDropDownIcon: true,
                  fileType: "empty",
                },
                {
                  drive_folder_id: "trash",
                  parent_id: null,
                  name: "common:drive.tree_nav.trash",
                  title: "",
                  type: 11,
                  updated_date: "",
                  extension: "folder",
                  parent: "drive",
                  droppable: false,
                  draggable: false,
                  fileType: "trash",
                  id: "trash",
                  format_type: "trash",
                  notShowDropDownIcon: true,
                },
                // {
                //   fileType: "trash",
                //   drive_folder_id: 'trash',
                //   id: 'trash',
                //   parent: 'drive',
                //   name: "common:drive.tree_nav.trash",
                //   droppable: true,
                //   draggable: false,
                //   type: 11,
                //   notShowDropDownIcon: true,
                // },
              ]);
              return new Promise((resolve) => {
                resolve(true);
              });
            }
          });
        } else {
          driveFoldersNav.set((v) => {
            const data = v.filter((a) => {
              if (a.type === 6 && userInfo.get({ stealth: true })) {
                a.name = userInfo.get({ stealth: true }).tenant_name;
              }
              return true;
            });

            return data.sort((a, b) => {
              if (a.type === 7) {
                return -1;
              } else if ([1, 2, 3, 4, 5].includes(a.type)) {
                return a.type - b.type;
              } else {
                return 0;
              }
            });
          });
        }
      });
    }
  }, [userInfo]);
  function onConfirmDialogClose(e, reason, isOk) {
    console.log(e, reason, isOk);
  }
  // const { enqueueSnackbar } = useSnackbar();
  // useEffect(()=>{
  //   if (showInfoNoti.value.open){
  //     enqueueSnackbar(showInfoNoti.value.message,)
  //   }
  // }, [showInfoNoti])
  if (
    !userInfo.get({ noproxy: true }) &&
    !window.location.href.includes("/guest/document") &&
    !window.location.href.includes("/shared-folder/delivery")
  )
    return <Loading />;
  return (
    <SnackbarProvider maxSnack={3}>
      <div
        className={`main contrainer-wrapper ${
          isCompany ? "modal_company_tree" : ""
        }`}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={showAlert.show.get()}
          autoHideDuration={3500}
          onClose={handleClose}
        >
          <Alert severity={showAlert.type.get()}>
            {showAlert.message.get()}
          </Alert>
        </Snackbar>

        <DriveConfirmDialog
          {...confirmDialog.get({ noproxy: true })}
          onClose={onConfirmDialogClose}
        />
        {/* <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showInfoNoti.show.get()}
          autoHideDuration={4000}
          onClose={(e, reason) => {
            if (reason === "clickaway") {
              return;
            }

            showInfoNoti.show.set(false);
          }}
        >
          <Alert severity={"warning"}>
            {showInfoNoti.message.get({ noproxy: true })}
          </Alert>
        </Snackbar> */}
        <div
          className="layout"
          onClick={(e) => {
            // console.log([...e.target.classList].some((a) => a.includes("drive_file_")));
            // console.log(e?.target?.classList)
            if (
              e.target.nodeName === "DIV" &&
              ![...e?.target?.classList].some(
                (a) =>
                  a.includes("drive_file_") ||
                  a.includes("folder-file_list") ||
                  a.includes("mui_select_backdrop")
              )
            ) {
              driveFileDrawer.open.set(false);
            }
          }}
        >
          <DriveLayoutLeft
            fromRoot={fromRoot}
            isCompany={isCompany}
            isGuest={isGuest}
            fromDocumentSelect={fromDocumentSelect}
          />

          <div className="layout-right is-drive">
            {isCompany ? null : (
              <div className="layout-right-top">
                <div className="top-middle">
                  <div className="system-notification">
                    {
                      // @ts-ignore
                      userInfo.get({ noproxy: true })?.content
                    }
                  </div>
                </div>

                {!isGuest ? (
                  <div className="top-right">
                    <div
                      className="icon notification"
                      style={{ display: "none" }}
                    >
                      <IconNotification />
                    </div>
                    <Tooltip title={t('common:tooltip.common.go-to-faq')} arrow>
                      <IconButton
                        aria-label="FAQ"
                        href={
                          window.location.pathname.includes("guest/document/")
                            ? "https://support.paperlogic.jp/manual/%e3%82%b2%e3%82%b9%e3%83%88%e7%94%a8%e6%93%8d%e4%bd%9c%e3%83%9e%e3%83%8b%e3%83%a5%e3%82%a2%e3%83%ab/"
                            : "https://support.paperlogic.jp/"
                        }
                        target="_blank"
                      >
                        <HelpOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <TenantSwitch userInfo={userInfo.get({ noproxy: true })} />
                    <UserInfoMenu userInfo={userInfo.get({ noproxy: true })} />
                  </div>
                ) : null}
              </div>
            )}

            <div
              className="right-body right_body_drive"
              onClick={(e) => {
                globalAnchorContextMenu.set(null);
              }}
              onContextMenu={(e) => {
                // console.log(e);
                if (fromDocumentSelect) return;
                if (
                  e.target.nodeName === "DIV" &&
                  (e.target.classList.length === 0 ||
                    e.target.className === "folder_header_breadcrumb")
                ) {
                  e.preventDefault();
                  globalAnchorContextMenu.set(
                    globalAnchorContextMenu.get({
                      stealth: true,
                      noproxy: true,
                    }) === null
                      ? {
                          mouseX: e.clientX + 2,
                          mouseY: e.clientY - 6,
                        }
                      : null
                  );
                }
                if (e.target.className.includes("MuiBackdrop-root")) {
                  e.preventDefault();
                  globalAnchorContextMenu.set(null);
                  globalAnchorContextMenu.set(
                    globalAnchorContextMenu.get({
                      stealth: true,
                      noproxy: true,
                    }) === null
                      ? {
                          mouseX: e.clientX + 2,
                          mouseY: e.clientY - 6,
                        }
                      : null
                  );
                }
              }}
            >
              {children ? children : null}
            </div>
            {/* <Body {...props} /> */}

            <div className="right-bottom"></div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
