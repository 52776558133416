// @ts-nocheck
import { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  // BrowserRouter,
  Switch
} from "react-router-dom";

import { createBrowserHistory } from "history";
import { Router } from "react-router";
import AuthRouter from "./Auth";

// import MainRoute from "./Main";
// import DocumentsRoute from "./Documents";

import ResendVerifyEmail from "../Pages/Auth/Registration/ResendVerifyEmail";
import Verification from "../Pages/Auth/Registration/Verification";
import Test from "../Pages/Test";
import StaticTest from "../Test";
import Pages from "./Pages";
// import Layout from '../Layout';
// import { withTranslation } from 'react-i18next';
import { useHookstate } from "@hookstate/core";
import {
  appLoadingShow,
  errProps,
  isSystemMaintain
} from "../data-access/app.state";
import DriveLayout from "../drive-feature/components/drive-layout/drive-layout";
import DriveUploadProgess from "../drive-feature/components/drive-upload-progess/drive-upload-progess";
import {
  userInfoState
} from "../drive-feature/data-access/state";
import DriveDelivery from "../drive-feature/pages/drive-delivery/drive-delivery";
import DriveFolders from "../drive-feature/pages/drive-folders/drive-folders";
import DriveShared from "../drive-feature/pages/drive-shared/drive-shared";
import MyDrive from "../drive-feature/pages/my-drive/my-drive";
import Logout from "../Pages/Auth/Logout";
import Error from "../Pages/Error";
import FileExplorer from "../Pages/Fragments/FileExplorer";
import Loading from "../Pages/Loading";
import BusinessNumberSearch from "../Pages/Main/BusinessNumberSearch";
import TenantDetailPages from "../Pages/Settings/Tenants/TenantDetail";
import SystemMaintain from "../Pages/SystemMaintaince/system-maintain";
import Service from "../Service";
import SystemService from "../Service/System";
import IdleTimerComponent from "./IdleTimer";
/**
 * SET USER IDEAL TIME WITH DEBOUNCE
 */
export const loader = document.querySelector("#loader");
// if you want to show the loader when React loads data again
export const showLoader = () => loader?.classList.remove("loader--hide");
export const hideLoader = () => loader?.classList.add("loader--hide");
export const bhistory = createBrowserHistory();
export default function Index(props) {
  // let { userInfo } = props;
  const userAuth = useHookstate(userInfoState);
  const userInfo = userAuth.get({ noproxy: true });
  const errors = useHookstate(errProps);
  const isMaintain = useHookstate(isSystemMaintain);
  /* */
  const loading = useHookstate(appLoadingShow);
  let settingsMenu = null;
  let mainMenu = null;
  let driveMenu = [];
  function callApiFre(list) {
    let queue = Promise.resolve(); // start waiting
    list.forEach(function (url) {
      queue = queue.then(function (result) {
        // put result somewhere
        return Service.GetPageData(url[0], url[1]);
      });
    });
    queue.then(function () {
      // all done here
    });
  }

  useEffect(() => {
    SystemService.GetVersionSystem().then((res) => {
      if (res && res.ok) {
        if (res.payload[0]) {
          localStorage.setItem(
            "current-version",
            res.payload[0].last_version_number
          );
        } else {
          localStorage.setItem("current-version", "1.0.0");
        }
      } else {
        localStorage.setItem("current-version", "1.0.0");
      }
    });
  }, []);

  useEffect(() => {
    if (userAuth.value) {
      // if (window.location.pathname.includes("/signer")) {
      //   callApiFre(["sender", 2], ["circle", 3], ["verification", 4]);
      // } else if (window.location.pathname.includes("/sender")) {
      //   callApiFre(["signer", 1], ["circle", 3], ["verification", 4]);
      // } else if (window.location.pathname.includes("/circle")) {
      //   callApiFre(["signer", 1], ["sender", 2], ["verification", 4]);
      // } else if (window.location.pathname.includes("/verification")) {
      //   callApiFre(["signer", 1], ["sender", 2], ["circle", 3]);
      // } else {
      //   callApiFre(["signer", 1], ["sender", 2], ["circle", 3], ["verification", 4]);
      // }

      hideLoader();
    }
  }, [userAuth]);
  if (isMaintain.get()) {
    return <SystemMaintain />;
  }

  if (errors.show.get()) {
    return <Error {...errors.get({ noproxy: true })} />;
  }
  if (!userInfo) {
    return <AuthRouter />;
  }

  if (userInfo) {
    settingsMenu = "";

    if (userInfo.administrator_role == "1") {
      settingsMenu = [
        <Route
          key="tenant"
          exact
          path="/settings/tenant"
          component={Pages.SettingsTenant}
        />,
        <Route
          key="tenantDetail"
          exact
          path="/settings/tenant/:tenantId"
          component={TenantDetailPages}
        />,
        <Route
          key="organization"
          exact
          path="/settings/organization"
          component={Pages.SettingsOrganization}
        />,
        <Route
          key="user"
          exact
          path="/settings/user"
          component={Pages.SettingsUser}
        />,
        <Route
          key="guest"
          exact
          path="/settings/guest"
          component={Pages.SettingsGuest}
        />,
        <Route
          key="notification"
          exact
          path="/settings/notification"
          component={Pages.SettingsNotification}
        />,
        <Route
          key="counter-party"
          exact
          path="/settings/counter-party"
          component={Pages.SettingsCounterParty}
        />,
        <Route
          key="expiration-notification"
          exact
          path="/settings/expiration-notification"
          component={Pages.SettingsExpirationDate}
        />,
        <Route
          key="sso-setting"
          exact
          path="/settings/sso-setting"
          component={Pages.SettingsSso}
        />,
        <Route
          key="restrictIp-setting"
          exact
          path="/settings/restrictIp-setting"
          component={Pages.SettingRestrictIp}
        />,
        <Route
          key="batch-download"
          exact
          path="/settings/batch-download"
          component={Pages.SettingBatchDownload}
        />,
        <Route
          key="audit-log"
          exact
          path="/settings/audit-log"
          component={Pages.SettingsAuditLog}
        />,
      ];

      if (userInfo.archive_mode !== 1) {
        settingsMenu.push(
          <Route
            key="app-cert"
            exact
            path="/settings/application-certificate"
            component={Pages.SettingsAppCert}
          />
        );
        settingsMenu.push(
          <Route
            key="stamp"
            exact
            path="/settings/stamp"
            component={Pages.SettingsStamp}
          />
        );
      }
    } else if (userInfo.administrator_role == "0") {
      settingsMenu = [
        <Route
          key="tenant"
          exact
          path="/settings/tenant"
          component={Pages.SettingsTenant}
        />,
        <Route
          key="tenantDetail"
          exact
          path="/settings/tenant/:tenantId"
          component={TenantDetailPages}
        />,
        <Route
          key="guest"
          exact
          path="/settings/guest"
          component={Pages.SettingsGuest}
        />,
        <Route
          key="counter-party"
          exact
          path="/settings/counter-party"
          component={Pages.SettingsCounterParty}
        />,
      ];
    }

    mainMenu = [];
    if (!userInfo["tenant_access_denied"]) {
      mainMenu.push(
        <Route
          key="dashboard"
          exact
          path="/dashboard"
          component={Pages.Dashboard}
        />
      );
    }

    mainMenu = [
      ...mainMenu,
      <Route key="search" exact path="/search" component={Pages.Search} />,
      <Route
        key="document-detail-page"
        exact
        path="/documents/detail/:document_id"
        component={Pages.DocumentDetailPage}
      />,
    ];

    if (userInfo.archive_mode !== 1) {
      mainMenu.push(
        <Route key="business-search" exact path="/business-search">
          <Suspense
            fallback={
              <div className="main-body-loading">
                <Loading />
              </div>
            }
          >
            <BusinessNumberSearch />
          </Suspense>
        </Route>
      );
    }

    if (userInfo.partner_id == "7") {
      if (userInfo.sender_role == "1") {
        mainMenu.push(
          <Route key="sender" exact path="/sender" component={Pages.Sender} />
        );
      }
    } else if (userInfo.partner_id !== "1" && userInfo.archive_mode !== 1) {
      if (userInfo.signer_role == "1") {
        mainMenu.push(
          <Route key="signer" exact path="/signer" component={Pages.Signer} />
        );
      }
      if (userInfo.sender_role == "1") {
        mainMenu.push(
          <Route key="sender" exact path="/sender" component={Pages.Sender} />
        );
      }
      if (userInfo.circle_role == "1") {
        mainMenu.push(
          <Route key="circle" exact path="/circle" component={Pages.Circle} />
        );
      }
    }

    if (userInfo.verification_role == "1") {
      mainMenu.push(
        <Route
          key="verification"
          exact
          path="/verification"
          component={Pages.Verification}
        />
      );
    }
    if (userInfo.evaluation_role == "1") {
      mainMenu.push(
        <Route
          key="evaluation"
          exact
          path="/evaluation"
          component={Pages.Evaluation}
        />
      );
    }
    if (userInfo.filebox_role == "1" && userInfo.archive_mode !== 1) {
      // mainMenu.push(
      //   <Route key="filebox" exact path="/filebox" component={Pages.FileBox} />
      // );
      driveMenu = [
        <Route exact path="/drive" key={1}>
          <Suspense
            fallback={
              <div className="main-body-loading">
                <Loading />
              </div>
            }
          >
            <DriveLayout fromRoot={true} children={<MyDrive />} />
          </Suspense>
        </Route>,

        <Route exact path="/delivery" key={2}>
          <Suspense
            fallback={
              <div className="main-body-loading">
                <Loading />
              </div>
            }
          >
            <DriveLayout children={<DriveDelivery />} />
          </Suspense>
        </Route>,

        <Route key={4} exact path="/drive/share/:userId">
          <Suspense
            fallback={
              <div className="main-body-loading">
                <Loading />
              </div>
            }
          >
            <DriveLayout children={<MyDrive />} />
          </Suspense>
        </Route>,
        <Route key={5} exact path="/folders/:folderId">
          <Suspense
            fallback={
              <div className="main-body-loading">
                <Loading />
              </div>
            }
          >
            <DriveLayout children={<DriveFolders />} />
          </Suspense>
        </Route>,
      ];
    }
    if (userInfo.workflow_role == "1") {
      mainMenu.push(
        <Route
          key="workflow"
          exact
          path="/workflow"
          component={Pages.Workflow}
        />
      );
    }
  }

  return (
    <>
      <IdleTimerComponent />
      <DriveUploadProgess />
      {loading.get() ? (
        <div className="full-body-loading">
          <Loading />
        </div>
      ) : null}
      {/* {!userInfo ? } */}
      <Router history={bhistory}>
        <Switch>
          {/* <Route key="workflow" exact path="/workflow" component={Pages.Workflow} />; */}

          <Route
            path="/registration/verification/:token"
            component={Verification}
          />
          <Route
            path="/registration/resend-email"
            component={ResendVerifyEmail}
          />
          <Route exact path="/sitemap" component={Pages.SiteMap} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/file-explorer" component={FileExplorer} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/layout" component={StaticTest} />

          {/* Main router group */}
          <Redirect exact from="/" to="/dashboard" />
          <Redirect exact from="/login" to="/dashboard" />

          {/* Main router group */}
          <Route
            exact
            path="/terms-of-service"
            component={Pages.TermsOfService}
          />

          {mainMenu}
          <Route key={3} exact path="/shared-folder/delivery/detail/:token">
            <Suspense
              fallback={
                <div className="main-body-loading">
                  <Loading />
                </div>
              }
            >
              <DriveLayout children={<DriveShared />} isGuest={true} />
            </Suspense>
          </Route>
          {/* DriveFeature */}
          {driveMenu}
          {/* End DriveFeature */}
          {/* <Route exact path="/dashboard" component={Pages.Dashboard} />
                    <Route exact path="/signer" component={Pages.Signer} />
                    <Route exact path="/sender" component={Pages.Sender} />
                    <Route exact path="/verification" component={Pages.Verification} />
                    <Route exact path="/filebox" component={Pages.FileBox} />
                    <Route exact path="/search" component={Pages.Search} />
                    <Route exact path="/evaluation" component={Pages.Evaluation} />
                    <Route exact path="/circle" component={Pages.Circle} /> */}

          {!userInfo["tenant_access_denied"] && (
            <Route
              exact
              path="/settings/profile"
              component={Pages.SettingsProfile}
            />
          )}
          {settingsMenu}
          {/* <Route exact path="/settings/tenant" component={Pages.SettingsTenant} />
                    <Route exact path="/settings/organization" component={Pages.SettingsOrganization} />
                    <Route exact path="/settings/user" component={Pages.SettingsUser} />
                    <Route exact path="/settings/guest" component={Pages.SettingsGuest} />
                    <Route exact path="/settings/stamp" component={Pages.SettingsStamp} />
                    <Route exact path="/settings/notification" component={Pages.SettingsNotification} />
                    <Route exact path="/settings/application-certificate" component={Pages.SettingsAppCert} /> */}

          {/* Document router group */}
          <Route
            exact
            path="/:type/documents/create"
            component={Pages.Documents}
          />
          <Route
            exact
            path="/:type/documents/create-with-template"
            component={Pages.Documents}
          />
          <Route
            exact
            path="/:type/documents/create/:mode"
            component={Pages.Documents}
          />
          <Route
            exact
            path="/:type/documents/edit/:document_id"
            component={Pages.Documents}
          />
          <Route
            exact
            path="/documents/sign/:document_id"
            component={Pages.DocumentSign}
          />
          <Route
            exact
            path="/documents/preview/:document_id"
            component={Pages.DocumentPreviewForPrinting}
          />
          <Route
            exact
            path="/guest/documents/preview/:token"
            component={Pages.DocumentPreviewForPrinting}
          />
          <Route
            exact
            path="/guest/document/:token"
            component={Pages.DocumentSign}
          />
          <Route
            exact
            path="/guest/linkage/:token"
            component={Pages.GuestLinkage}
          />
          <Route
            exact
            path="/guest/approve/:token"
            component={Pages.GuestApprove}
          />
          <Route
            exact
            path="/guest/collaboration/:token"
            component={Pages.GuestCollaboration}
          />
          <Route
            exact
            path="/tenant/collaboration/:token"
            component={Pages.TenantCollaborationApprove}
          />
          {/* <Pages.Documents type="signer" /> */}
          {/* </Route> */}
          {/* <Route path="/sender/documents/creation">
                        <Pages.Documents type="sender" />
                    </Route> */}
          {/* Search router group */}
          <Route exact path="/search/result" component={Pages.SearchResult} />
          {/* Application Certificate group */}
          <Route
            exact
            path="/batch-download/detail/:batchId"
            component={Pages.SettingBatchDownload}
          />
          <Route
            exact
            path="/appcert/upload/:application_date"
            component={Pages.AppCertUpload}
          />
          <Route
            exact
            path="/guest/appcert/:token"
            component={Pages.AppCertUpload}
          />
          {/* PDF Full Preview */}
          <Route
            exact
            path="/pdf-full-preview"
            component={Pages.PDFFullPreview}
          />
          <Route exact path="/account/recovery/reset-password/:token">
            <Pages.Error
              code={404}
              msg={
                userInfo.language === "ja"
                  ? "すでに別のアカウントでログインしています。\nログアウトをして再度お試しください。"
                  : "general.error-404.signed-in-with-another-account"
              }
              isCustom={true}
              layout={true}
            />
          </Route>
          <Route exact path="/user/verification/:token">
            <Pages.Error
              code={404}
              msg={
                userInfo.language === "ja"
                  ? "すでに別のアカウントでログインしています。\nログアウトをして再度お試しください。"
                  : "general.error-404.signed-in-with-another-account"
              }
              isCustom={true}
              layout={true}
            />
          </Route>

          <Route>
            <Pages.Error
              code={
                !userInfo["tenant_access_denied"] ? 404 : "tenant_access_denied"
              }
              layout={true}
            />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
