import '../Assets/css/settings.css';
import React from 'react';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import { withTranslation } from "react-i18next";
import { Form, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

class Stamp extends Layout {

	/** */
	constructor(props) {

		/* */
		super(props);
		this.state.page = "stamp";
		this.state.pagination = {
			"page": 1,
			"take": 100,
			"total": 0,
		};
		this.state.datalist = null;
		this.state.userlist = null;
		this.state.grouplist = null;
		this.state.groupIndex = null;
		this.state.stamp_users = null;
		this.state.stamp_detail = null;
		// this.state.keyFields = {
		// 	"tenant_id": "tenant_id",
		// 	"group_id": "group_id",
		// 	"user_id": "user_id",
		// 	"email": "email",
		// 	"status": "status",
		// 	"certificate_type": "certificate_type",
		// 	"private_file": "private_file",
		// 	"file_password": "file_password",
		// 	"file_extension": "file_extension",
		// 	"cert_exp_date": "cert_exp_date",
		// };
		/* !! TODO DUMMY DATA !! */
		this.state.activeTab = 0;
		this.state.tabs = [
			"Tabs-1",
			"Tabs-2",
			"Tabs-3",
		];
		this.state.datalist = null;
		/* */
		this.state.create = {
			"users": [],
		};
		this.state.search = "";
		this.state.keyFields = {
			group_id: "user-id",
			group_custom_label: "group-custom-label",
			cert_file: "certificate.file-select",
			cert_file_name: "certificate.file-select",
			cert_password: "certificate.password",
			stamp_img: "stamp-img",
			active_flg: "active_flg",
		};
		this.state.formData = {};
	}

	/** [RENDER] */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {
		//
		this.setStateFields();
	}

	componentDidUpdate() {

		/* */
		let { datalist, pagination } = this.state;

		/* */
		if (datalist === null) {

			/* */
			let page = pagination.page;
			let take = pagination.take;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"search": this.state.search,
				"skip": skip,
				"take": take,
			}

			Service.getStampList(data).then((response) => {

				/* */
				let datalist = response.records;
				pagination.total = response.records.length;
				/* */
				this.setState({ datalist });

			}).catch((error) => {
				console.log(error.response);
			});
		}
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.stamp.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			} else if (!formData.hasOwnProperty(key)) {
				formData[key] = fields[key].value;
			}
		}

		this.setState({ fields, formData }, cb);
	}

	GroupItems = () => {
		let { t } = this.props;
		let { grouplist } = this.state;
		let ItemElements = [];

		ItemElements.push(<option key="empty-value" value="">{t("common:messase.stamp.please-select-group")}</option>);
		for (let i in grouplist) {
			let group = grouplist[i];
			ItemElements.push(<option key={group.group_id} value={group.group_id}>{group.group_name}</option>);
		}

		return ItemElements;
	}

	formSubmitCallback = (ev) => {
		// const form = ev.target;
		let { formData, modal } = this.state;

		let form = document.querySelector("#stamp-input-form");
		formData["active_flg"] = form.elements["active_flg"]["checked"] ? 0 : 1;
		delete modal.props.size;
		modal.body = Loading;
		modal.action = [];

		this.setState({ modal }, this.StampSave);
		// if (form.action_name.value === "create") {
		// } else {
		// 	this.StampUpdate(form);
		// }
	}

	/** [RENDER] */
	DataRows = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;

		/* */
		if (datalist === null) {
			return <LoadingList />;
		}
		/* */
		let rows = [];

		/* */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let item = datalist[i];

			// /* */
			// let eventClickRow = (event) => {
			// 	if(event.target.nodeName !== "BUTTON") {
			// 		this.openUpdateFormDialog(event);
			// 	}
			// };

			/* */
			// let eventClickButton = () => {
			// 	alert(i);
			// };

			/* */
			// let certClass = "not-available";

			// if (item["certificate"] === "あり") {
			// 	certClass = "available";
			// }

			/* */
			let row = (
				// <tr key={i} onClick={ eventClickRow }>
				<tr className='document-row-list' key={i}>
					<td className="clickable" onClick={(ev) => { this.openCreateFormDialog(ev, item) }} >
						<div>{item["group_name"]}</div>
					</td>
					<td className="clickable" onClick={(ev) => { this.openCreateFormDialog(ev, item) }} >
						{/* <button key="click" type="button" className={`lz-m-0-10 ` + certClass} onClick={ eventClickButton }>
							<span>{ item["certificate"] }</span>
						</button> */}
						<div>
							<span>{item["certificate"]}</span>
						</div>
					</td>
					<td className="clickable" onClick={(ev) => { this.openCreateFormDialog(ev, item) }} >
						<div>{item["status_name"]}</div>
					</td>
					<td className="stamp-delete">
						<IconButton aria-label="delete"
							onClick={(ev) => {
								this.DeleteDocumentConfirmation(ev, item["stamp_id"]);
							}}>
							<DeleteIcon />
						</IconButton>
					</td>
					<td>
						<input type="hidden" name="tenant_id" defaultValue={item["stamp_id"]} />
						<input type="hidden" name="tenant_id" defaultValue={item["tenant_id"]} />
						<input type="hidden" name="group_id" defaultValue={item["group_id"]} />
						<input type="hidden" name="status" defaultValue={item["status"]} />
					</td>
				</tr>
			);

			/* */
			rows.push(row);

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.stamp.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<table id="datalist" className="stamp-setting-list">
				<thead>
					<tr className={"shed"}>
						<th>
							<div>{t("common:settings.stamp.list-department")}</div>
						</th>
						<th>
							<div>{t("common:settings.stamp.list-certificate")}</div>
						</th>
						<th>
							<div>{t("common:settings.stamp.list-active-flg")}</div>
						</th>
						<th>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		);

	}

	DeleteDocumentConfirmation = (ev, stamp_id) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "stamp-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.stamp.delete.comfirm.header";

		/* */
		modal.body = "settings.stamp.delete.comfirm.message";

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [(
			<Button key="ok" variant="contained" onClick={(ev) => {
				let { modal } = this.state;
				modal.body = Loading;
				modal.action = [];
				this.setState({ modal }, () => {
					this.StampDelete(stamp_id);
				});
			}}>
				<span>{t("common:general.yes-delete")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });

	}

	UpdateModalDialog = (options) => {
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		if (options.title) {
			modalTitle = options.title;
		}
		if (options.body) {
			ModalBody = options.body;
		}
		if (options.action) {
			ModalAction = options.action;
		}
		if (options.props) {
			modalProps = options.props;
		}
		this.setState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	/** [RENDER] */
	CreateButton = () => {

		/* Search Function */
		let { t } = this.props;

		// /* */
		// let { datalist } = this.state;

		// /* */
		// if (datalist === null) {
		// 	return (
		// 		<div key="not-avairable" className="lz-btn-mini lz-mr-8">
		// 			<Button key="create" title="Action">
		// 				<span>{t("common:settings.stamp.button-create")}</span>
		// 			</Button>
		// 		</div>
		// 	);
		// }

		/* */
		return (
			<div key="avairable">
				<Button
					sx={{ borderRadius: 19 }}
					key="create"
					title="Action"
					disabled={!this.state.datalist}
					variant="contained"
					startIcon={<AddIcon />}
					onClick={() => { this.openCreateFormDialog() }}
				>
					{t("common:settings.stamp.button-create")}
				</Button>

			</div>
		);

	}

	/** [RENDER] */
	SelectTab = () => {

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {
				this.updateTab(i);
			};

			/* */
			let tab = (
				<div key={i} className={c} onClick={clickEvent}>
					{/* <div key={i} className={c} onClick={ clickEvent }> */}
					<span>{this.state.tabs[i]}</span>
				</div>
			);

			/* */
			tabs.push(tab);

		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);

		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] = true;
		}

		/* */
		let start_list = 0;
		let last_list = 0;
		if (pagination.total > 0) {
			start_list = 1 + ((pagination.page - 1) * pagination.take);
			if (pagination.page === max) {
				last_list = pagination.total
			} else {
				last_list = start_list + (pagination.take - 1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	/** [RENDER] */
	render() {

		/* */
		// let { t } = this.props;

		/* */
		const { Main } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-stamp" className="page-settings">

					{/* Tabs */}
					{/* <div className="lz-flex">

						<div className="lz-flex-1">
							<div className=""></div>
						</div>

						<div className="lz-flex-0">

							<div className="lz-m-10">
								{ <this.SelectTab /> }
							</div>

						</div>

					</div> */}

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								{/* SearchForm */}
								<div className="lz-flex">

									<this.CreateButton />

									{/* <div className="input-textbox">
										<input type="search" name="" placeholder={t("common:settings.stamp.search-placeholder")} />
									</div> */}

								</div>

							</div>

						</div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="body-wrapper">
						<this.DataRows />
					</div>

					{/* BottomPaginate */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}

	/** [EVENT] */
	updateTab = (active) => {

		this.setState({ "activeTab": active });

	}

	/** [Action] */
	openCreateFormDialog = (ev, data) => {
		this.setState({
			formData: {},
			userlist: null,
			grouplist: null,
			groupIndex: null,
		}, () => {
			this.setStateFields(() => {
				this.CreateFormDialog(data);
			});
		});
	}

	CreateFormDialog = (data) => {
		/* Prepare Modal */
		let { modal, create, formData, UserInfo } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */
		create.users = [];
		formData["operation_type"] = "insert";
		if (data) {
			create.users = null;
			formData["operation_type"] = "update";
		}

		/* Show Modal */
		modal.props = {
			"show": true,
			"size": "xl",
			"centered": true,
			"dialogClassName": "stamp-dialog",
		}

		/* */
		modal.title = "common:settings.stamp.dialog-title";

		/* */
		modal.body = this.userSelectionBody;

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		// modal.action.push(
		// 	<Button key="ok" variant="primary" onClick={this.createFormDialog_DataForm}>
		// 		<span>{t("common:general.next")}</span>
		// 	</Button>
		// );

		let cbFinal = () => {
			let { modal } = this.state;
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={() => {
					if (this.state.create.users.length === 0) {
						let element = document.getElementById("error-message");
						element.innerText = t("common:general.error-message.select-user.none");
						return;
					}
					this.createFormDialog_DataForm()
				}}>
					<span>{t("common:general.next")}</span>
				</Button>
			)];
			this.setState({ modal });
		}

		/* */
		// console.log(data);
		this.setState({ modal, create, formData }, () => {
			this.getUserGroupList(data, () => {
				if (data) {
					this.getUpdateDetailData(data, cbFinal);
				} else {
					formData["group_custom_label"] = UserInfo.tenant_name;
					cbFinal();
				}
			});
		});
	}

	getUserGroupList = (data, cb) => {
		/* */
		Service.getStampUserGroupList({
			"search": this.state.search,
		}).then((response) => {

			/* */
			let { userlist, grouplist, groupIndex } = this.state;

			/* */
			// console.log(response);
			userlist = response.users;
			grouplist = response.groups;
			if (data) {
				grouplist = response.all_groups;
			}
			groupIndex = {}
			for (let group of grouplist) {
				groupIndex[group.group_id] = group;
			}

			/* */
			this.setState({ userlist, grouplist, groupIndex }, cb);
		}).catch((error) => {
			console.log(error.response);
		});
	}

	userSelectionBody = () => {

		/* */
		return (
			<div id="settings-stamp" className="">

				{/*  */}
				<div className="lz-m-10">

					{/* User-Filter
						<div className="row">
							<div className="col-xl-12">
								
								<Form.Group className="">
									<Form.Control type="search" />
								</Form.Group>

							</div>
						</div> */}

					{/* User-Selection */}
					<div className="row settings-stamp-entry-list">
						<div className="col-xl-6 col-md-6 col-sm-6 settings-stamp-entry-list-col">
							<div className="user-list">
								{this.createFormDialog_userSelection()}
							</div>
						</div>
						<div className="col-xl-6 col-md-6 col-sm-6 settings-stamp-entry-list-col">
							<div className="user-list">
								{this.createFormDialog_userLookup()}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 col-md-6 col-sm-6">
						</div>
						<div className="col-xl-6 col-md-6 col-sm-6 user-selection-error">
							<label id="error-message" value="" />
						</div>
					</div>
				</div>

			</div>
		);
		// }

		// this.updateState({ modal });
		// this.setState({ FileboxStorage });
	}

	/** [Elements] */
	createFormDialog_userLookup = () => {

		/* */
		let { userlist, create } = this.state;

		if (!userlist || !create.users) {
			return <Loading />
		}

		/* */
		let nodelist = [];
		let ignore = [];

		/* */
		if (create.users) {
			for (let user of create.users) {
				ignore.push(user.user_id);
			}
		}

		/* */
		// for (let i = 0; i < userlist.length; i++) {
		for (let user of userlist) {

			/* Skip when user already exists in selection */
			if (ignore.includes(user.user_id)) {
				continue;
			}

			/* */
			nodelist.push(
				<div className={`user-list-item clickable`} key={user.user_id} onClick={() => this.createFormDialog_addUser(user)}>
					<div className="lz-flex">
						<div className="lz-flex-0">
							<span className='iconImg'><AddCircleOutlineIcon color="primary" /></span>
						</div>
						<div className="lz-flex-1">
							<div className="user-title">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.full_name}</span>
							</div>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Elements] */
	createFormDialog_userSelection = () => {

		/* */
		let { userlist, create } = this.state;

		if (!userlist || !create.users) {
			return <Loading />
		}

		let nodelist = [];
		/* */
		for (let user of create.users) {
			/* */
			nodelist.push(
				<div className="user-list-item" key={user.user_id}>
					<div className="lz-flex">
						<div className="lz-flex-0">
							<span className='iconImg'><CheckCircleIcon color="success" /></span>
						</div>
						<div className="lz-flex-1">
							<div className="user-title">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.full_name}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<IconButton
								size='small'
								onClick={() => { this.createFormDialog_removeUser(user) }}>
								<HighlightOffIcon fontSize='small' />
							</IconButton>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Action] */
	createFormDialog_addUser = (user) => {

		/* */
		let { create } = this.state;

		/* */
		let newObjUser = JSON.parse(JSON.stringify(user));
		newObjUser.status = 0;
		newObjUser.name = user.full_name;

		create.users.push(newObjUser);

		this.setState({ create }, this.setCreateStateFields);
	}

	setCreateStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		/* */
		let users = this.state.create.users;

		// this.state.fields = {};
		for (let i = 0; i < users.length; i++) {
			let user = users[i];

			for (let key in keyFields) {
				let label = keyFields[key];
				fields[key] = {
					name: key,
					value: user[key],
					label: t(`common:settings.stamp.${label}`),
				};
				if (formData[key]) {
					fields[key].value = formData[key];
				}
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Action] */
	createFormDialog_removeUser = (targetUser) => {
		let { create } = this.state;
		let targetIndex = null;

		for (let i in create.users) {
			let user = create.users[i];
			if (user.user_id === targetUser.user_id) {
				targetIndex = i;
				break;
			}
		}

		if (targetIndex !== null) {
			create.users.splice(targetIndex, 1);
		}

		/* */
		this.setState({ create });
	}

	/** [Action] */
	createFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		modal.form = {
			id: "stamp-form",
			onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
			noValidate: true,
		};
		/* */
		modal.body = this.CreateFormDialog_UploadTable;

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="return" variant="primary" onClick={this.openCreateFormDialog_ReturnUserForm}>
				<span>{t("common:auth.general.back")}</span>
			</Button>
		);

		/* */
		modal.action.push(
			<Button key="ok" type="submit" sx={{ ml: 1 }} variant="contained" >
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modal, formValidate: false }, this.newCanvasGenerate);

	}

	/** [Action] */
	openCreateFormDialog_ReturnUserForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */

		modal.form = {};
		modal.body = this.userSelectionBody;


		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" variant="contained" onClick={() => {
				if (this.state.create.users.length === 0) {
					let element = document.getElementById("error-message");
					element.innerText = t("common:general.error-message.select-user.none");
					return;
				}
				this.createFormDialog_DataForm()
			}}>
				<span>{t("common:general.next")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });
	}

	createCanvas = (stamp_text) => {
		let substrGroupNameArr = [];

		// Make string layout
		for (let i = 0; i < stamp_text.length; i = i + 6) {
			substrGroupNameArr.push(stamp_text.substr(i, 6));
		}

		// Set start height
		let startHeight = 190 - ((substrGroupNameArr.length - 1) * 120);

		// Create canvas
		let size = 400;
		let canvas = document.createElement("canvas");
		canvas.width = size;
		canvas.height = size;
		canvas.style.border = "4px solid";
		let ctx = canvas.getContext("2d");
		ctx.font = "120px Verdana";
		ctx.textAlign = 'center';
		ctx.strokeStyle = 'red';
		ctx.lineWidth = 3;
		ctx.fillStyle = "#fff";
		ctx.strokeRect(0, 0, size, size);

		// Can display char byte Half 9 Full 6
		// Set string
		for (let i = 0; i < substrGroupNameArr.length; i++) {
			let displayHeight = startHeight + (i * 120);
			ctx.strokeText(substrGroupNameArr[i], 100, displayHeight);
			ctx.fillText(substrGroupNameArr[i], 100, displayHeight);
		}

		return canvas.toDataURL();
	}

	calcByte = (str) => {
		var length = 0;
		//for (var i = 0; i < str.length; i++) {
		//var c = str.substr(i, 1);
		//}
		return length;
	};

	newCanvasGenerate = () => {
		//console.log(211);
		// Do not wait
		setTimeout(() => {
			let { groupIndex, formData, UserInfo } = this.state;
			let group_name = "";

			if (groupIndex && formData) {
				let group_id = formData['group_id'];
				let group = groupIndex[group_id];
				group_name = formData["group_custom_label"] || (group && group.group_name) || "";
			}

			// var companyName = UserInfo.company_name;
			var companyName = UserInfo.tenant_name;
			//var text_length = new Blob([stamp_text]).size;  
			// group_name = "tt証明書dfgの選択sej[{])seฟห😂👍หกGJTYREFเเเเดห選ดggดนก";
			//stamp_text = "arttกg選jด";

			var size = 600;
			var image_h_companyName = 400;

			var fontSize = 42;
			var text_group = [companyName];
			var charPerRow = companyName.length;
			console.log(charPerRow);
			// var fullRowSize = size;
			var group_index = 0;
			var canvas = document.createElement("canvas");
			canvas.width = size + 20;
			canvas.height = size + 20;
			canvas.style.border = "4px solid";
			var ctx = canvas.getContext("2d");

			// var text_group = [];
			ctx.font = fontSize + "px Verdana";

			do {
				// fontSize -= 1;
				// ctx.font = "bold " + fontSize + "px Verdana";
				var text = text_group[group_index];
				let textMea = ctx.measureText(text);
				// var fontHeight = Math.floor(textMea.fontBoundingBoxAscent + textMea.fontBoundingBoxDescent);
				// var fontAcHeight = Math.floor(textMea.actualBoundingBoxAscent + textMea.actualBoundingBoxDescent);
				var nextRowCount = text_group.length + 1;

				if (textMea.width > size) {
					charPerRow = Math.ceil(companyName.length / nextRowCount);
					group_index = 0;
					text_group = [];
					for (let row = 0; row < nextRowCount; row++) {
						text_group.push(companyName.substring(row * charPerRow, (row + 1) * charPerRow));
					}
				} else {
					group_index += 1;
				}
			} while (group_index < text_group.length);
			console.log(text_group);

			for (let i in text_group) {
				let text = text_group[i];
				text_group[i] = {
					"text": text,
					"width": 600,
					"height": 60,
				};
			}

			var topPos = 100;
			text_group.push({
				"text": "",
				"width": 600,
				"height": image_h_companyName - topPos - (60 * text_group.length) - 80,
			});
			text_group.push({
				"text": group_name,
				"width": 600,
				"height": 100,
			});
			text_group.push({
				"text": "YYYY-MM-DD",
				"width": 600,
				"height": 80,
			});

			//fontSize -= 10
			// ctx.textAlign = 'center';
			// ctx.fillStyle = "#fff";
			ctx.strokeStyle = 'red';
			ctx.lineWidth = 2;

			// let fromBottom = 0;
			for (let i in text_group) {
				let group = text_group[i];
				let textMea = ctx.measureText(group.text);
				// let fontHeight = Math.floor(textMea.fontBoundingBoxAscent + textMea.fontBoundingBoxDescent);
				// let fontAcHeight = Math.floor(textMea.actualBoundingBoxAscent + textMea.actualBoundingBoxDescent);

				// console.log(textMea);
				// ctx.fillStyle = "rgb(228 0 0 / 20%)";
				// ctx.fillRect(0, topPos, size, fontHeight);
				let margin = (size - textMea.width) / 2;
				ctx.strokeText(group.text, margin + 10, topPos + group.height);
				// ctx.fillText(text, margin, topPos + fullRowSize - liftUp);
				topPos += group.height;
			}

			ctx.lineWidth = 4;
			ctx.strokeRect(2, 2, size + 16, size + 16);

			formData["stamp_img"] = canvas.toDataURL();
			this.setState({ formData });
			// return canvas.toDataURL();
		}, 100);
	}

	/* */
	eventClickButton = (ev) => {
		let selectedIndex = ev.target.selectedIndex;
		let groupName = ev.target.options[selectedIndex].text;

		document.getElementById("stamp_img").src = this.createCanvas(groupName);
	};

	CanvasPreview = () => {
		let { formData } = this.state;

		if (!formData["stamp_img"]) {
			return <Loading />;
		}

		return <img id="stamp_img" className="stamp-preview" alt="icon" src={formData["stamp_img"]} />;
	};

	SelectedUserNameList = () => {
		let { create } = this.state;

		if (!create.users) {
			return <Loading />;
		}
		// console.log(create.users);
		let UserNameList = [];
		for (let user of create.users) {
			UserNameList.push(
				<div key={user.user_id}>
					<span>{user.full_name}</span>
				</div>
			);
		}
		return UserNameList;
	}

	/** [Element] */
	CreateFormDialog_UploadTable = () => {

		/* Translate function */
		let { t } = this.props;
		let { formData, fields, formValidate } = this.state;
		let { FieldControl, ValidateMessage, CanvasPreview, GroupItems, SelectedUserNameList } = this;

		/* */
		let rows = [];

		let fileRequired = false;
		if (formData["operation_typ"] === "insert" && !formData["cert_file"]) {
			fileRequired = true;
		} else if (formData["operation_typ"] === "update" && !formData["base64_cert"]) {
			fileRequired = true;
		}

		/* */
		rows.push(
			<fieldset key="stamp_field" id={`stamp`} className="stamp-item-row">
				<div className="stamp-item">
					<Form.Row>
						<Form.Group as={Col} xs={12} md={6} className="form-group-canvas">
							<CanvasPreview />
						</Form.Group>
						<Form.Group as={Col} xs={12} md={6} >
							<Form.Row>
								<FieldControl name="group_id" as="select" required validate="true"
									onChange={(ev) => {
										this.onChangeHandler(ev);
										// this.eventClickButton(ev);
										let { formData } = this.state;
										formData["stamp_img"] = null;
										this.setState({ formData }, this.newCanvasGenerate);
									}}
								>
									<GroupItems />
								</FieldControl>
							</Form.Row>
							<Form.Row>
								<FieldControl name="group_custom_label" validate="true" maxlength="64"
									onChange={(ev) => {
										this.onChangeHandler(ev);
										let { formData } = this.state;
										// formData["stamp_img"] = null;
										this.setState({ formData }, this.newCanvasGenerate);
									}}
								/>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} className="form-control-file">
									<Form.Label>{t("common:settings.stamp.certificate.file-select")}</Form.Label>
									<Form.File
										id="cert_file_name"
										name="cert_file_name"
										label={formData['cert_file_name'] || t("common:settings.stamp.certificate.file-select")}
										custom
										accept=".pfx,.p12"
									>
										<Form.File.Input
											isValid={formData["cert_file_name"] && formValidate}
											isInvalid={!formData["cert_file_name"] && formValidate}
											required={fileRequired}
											onChange={(ev) => {
												// console.log(ev.target.files[0]);
												let { formData } = this.state;
												formData["cert_file"] = ev.target.files[0];
												formData["cert_file_name"] = ev.target.files[0].name;
												this.setState({ formData });
											}} />
										<Form.File.Label>
											{formData['cert_file_name'] || t("common:settings.stamp.certificate.file-select")}
										</Form.File.Label>
										<Form.Control.Feedback type="invalid">
											<ValidateMessage field={fields["cert_file_name"]} />
										</Form.Control.Feedback>
									</Form.File>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<FieldControl name="cert_password" type="password" required validate="true" />
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} className="">
									<Form.Label>{t("common:settings.stamp.user-list")}</Form.Label>
									<div className="form-control stamp-user-list custom-form-outline form-group col-md-12 col-12">
										<SelectedUserNameList />
									</div>
								</Form.Group>
							</Form.Row>
						</Form.Group>
					</Form.Row>
				</div>
			</fieldset>
		);
		// console.log(formData["active"]);
		/* */
		return (
			<div id="settings-stamp" className="">

				{/* <input type="file" id="upload" accept="image/*" /> */}

				{/*  */}
				<div className="lz-m-10">

					{/*  */}
					<div className="row">
						<div className="col-xl-12">
							<fieldset id="stamp-input-form" className="table-wrap-nothing">
								<div className="stamp">
									<input type="hidden" name="action_name" defaultValue="create" />
									<div id="stamp-form" className="stamp-itemlist">
										{rows}
									</div>
								</div>
								{/* <Form.Group className="lz-mt-10 status" controlId="active">
									<Form.Check
										type="switch"
										name='active'
										onChange={this.onChangeHandler}
										label={t('common:settings.user.active')}
										checked={true}
									/>
								</Form.Group> */}
								<div className="col-xl-12">
									<Form.Check type="switch" name="active_flg" label={t('common:settings.user.active')} defaultChecked="checked" />
								</div>
							</fieldset>
						</div>
					</div>

				</div>

			</div>
		);

	}


	/** 
	 * 
	 * 
	 * 
	 * UPdate
	 * 
	 * 
	 * 
	 */
	/** [Action] */
	openUpdateFormDialog = (data) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */
		let { create } = this.state;
		create.users = [];
		this.setState({ create });

		/* Show Modal */
		modal.props = {
			"show": true,
			"size": "xl",
			"centered": true,
			"dialogClassName": "stamp-dialog",
		}

		/* */
		modal.title = "common:settings.stamp.dialog-title";

		/* */
		modal.body = Loading;

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" variant="primary" onClick={() => {
				if (this.state.create.users.length === 0) {
					let element = document.getElementById("error-message");
					element.innerText = t("common:general.error-message.select-user.none");
					return;
				}
				this.updateFormDialog_DataForm()
			}}>
				<span>{t("common:general.next")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

		this.getUpdateUserGroupList(data);
	}

	getUpdateUserGroupList = (data) => {

		/* */
		let groupListData = {
			"search": this.state.search,
		}

		/* */
		Service.getStampUserGroupList(groupListData).then((response) => {

			/* */
			let { userlist, grouplist, groupIndex } = this.state;

			/* */
			userlist = response.users;
			grouplist = response.all_groups;
			groupIndex = {}
			for (let group of grouplist) {
				groupIndex[group.group_id] = group;
			}

			/* */
			this.setState({ userlist, grouplist, groupIndex });

			this.getUpdateDetailData(data);
		}).catch((error) => {
			console.log(error.response);
		});
	}

	getUpdateDetailData = (data, cb) => {

		/* */
		Service.getStampDetail(data).then((response) => {

			/* */
			let { create, formData, stamp_users, stamp_detail } = this.state;

			/* */
			stamp_users = response.stamp_users;
			create.users = response.stamp_users;
			stamp_detail = response.stamp_detail;

			/* */
			// console.log(response);
			// stamp_detail:
			// bin_cert: null
			// cert_pw: null
			// file_extension: ""
			// file_name: ""
			// group_id: 34
			// stamp_id: 44
			// status: 0
			// tenant_id: 46

			formData["stamp_id"] = stamp_detail["stamp_id"];
			formData["tenant_id"] = stamp_detail["tenant_id"];
			formData["group_id"] = stamp_detail["group_id"];
			formData["group_custom_label"] = stamp_detail["group_custom_label"];
			formData["base64_cert"] = stamp_detail["base64_cert"];
			formData["cert_file_name"] = stamp_detail["file_name"]; // + "." + stamp_detail.file_extension;
			formData["cert_password"] = stamp_detail["cert_pw"];
			// formData["stamp_img"] = null;
			formData["active_flg"] = stamp_detail["active_flg"] === 0 ? true : false;

			// console.log(create.users);
			this.setState({ create, formData, stamp_users, stamp_detail }, cb);

			// this.userUpdateSelectionBody();
		}).catch((error) => {
			console.log(error.response);
		});
	}

	userUpdateSelectionBody = () => {

		let { modal } = this.state;

		/* */
		modal.body = () => {
			return (
				<div id="settings-stamp" className="">

					{/*  */}
					<div className="lz-m-10">

						{/* User-Filter
						<div className="row">
							<div className="col-xl-12">
								
								<Form.Group className="">
									<Form.Control type="search" />
								</Form.Group>

							</div>
						</div> */}

						{/* User-Selection */}
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6">
								<div className="user-list">
									{this.updateFormDialog_userSelection()}
								</div>
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6">
								<div className="user-list">
									{this.updateFormDialog_userLookup()}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6">
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 user-selection-error">
								<label id="error-message" value="" />
							</div>
						</div>
					</div>

				</div>
			);
		}

		this.updateState({ modal });
		// this.setState({ FileboxStorage });
	}

	/** [Elements] */
	updateFormDialog_userLookup = () => {

		/* */
		let { userlist } = this.state;

		/* */
		let nodelist = [];
		let ignore = [];

		/* */
		for (let i = 0; i < this.state.create.users.length; i++) {
			ignore.push(this.state.create.users[i]['user_id']);
		}

		/* */
		for (let i = 0; i < userlist.length; i++) {

			let user = userlist[i];

			/* Skip when user already exists in selection */
			if (ignore.includes(user.user_id)) {
				continue;
			}
			/* */
			nodelist.push(
				<div className="user-list-item" key={i}>
					<div className="lz-flex">
						<div className="lz-flex-0">
							<Button key="addUser" variant="success" onClick={() => this.updateFormDialog_addUser(user)}>
								<FontAwesomeIcon icon={faAngleLeft} />
							</Button>
						</div>
						<div className="lz-flex-1">
							<div className="user-title">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.full_name}</span>
							</div>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Elements] */
	updateFormDialog_userSelection = () => {

		/* */
		let userlist = this.state.create.users;
		let nodelist = [];

		/* */
		for (let i = 0; i < userlist.length; i++) {

			/* */
			let user = userlist[i];

			/* */
			nodelist.push(
				<div className="user-list-item" key={i}>
					<div className="lz-flex">
						<div className="lz-flex-1">
							<div className="user-title">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.full_name}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<Button key="removeUser" variant="danger" onClick={() => { this.updateFormDialog_removeUser(user.user_id) }}>
								<span>-</span>
							</Button>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Action] */
	updateFormDialog_addUser = (user) => {

		/* */
		let { create } = this.state;

		/* */
		create.users.push({
			"status": 0,
			"tenant_id": user.tenant_id,
			"group_id": user.group_id,
			"user_id": user.user_id,
			"email": user.email,
			"user_type_name": user.user_type_name,
			"full_name": user.full_name,
			"current_tenant_id": user.current_tenant_id,
			"tenant_name": user.tenant_name,
		});

		this.setState({ create });
		this.setCreateStateFields();
	}

	/** [Action] */
	updateFormDialog_removeUser = (user_id) => {

		/* */
		let { create } = this.state;

		/* */
		for (let i = 0; i < create.users.length; i++) {
			let user = create.users[i];

			if (user.user_id === user_id) {
				create.users.splice(i, 1);
			}
		}

		/* */
		this.setState({ create });

	}

	/** [Action] */
	updateFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		modal.form = {
			id: "stamp-form",
			onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
			noValidate: true,
		};
		/* */
		modal.body = this.UpdateFormDialog_UploadTable;

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="return" variant="primary" onClick={() => { this.openUpdateFormDialog_ReturnUserForm() }}>
				<span>{t("common:auth.general.back")}</span>
			</Button>
		);

		/* */
		modal.action.push(
			<Button key="ok" type="submit" variant="primary" >
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

	}

	/** [Action] */
	openUpdateFormDialog_ReturnUserForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" variant="primary" onClick={() => {
				if (this.state.create.users.length === 0) {
					let element = document.getElementById("error-message");
					element.innerText = t("common:general.error-message.select-user.none");
					return;
				}
				this.updateFormDialog_DataForm()
			}}>
				<span>{t("common:general.next")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

		this.userUpdateSelectionBody();

	}

	setCanvasUpdate = () => {

		let {
			grouplist,
			// formData, 
			stamp_detail
		} = this.state;

		if (grouplist.length === 0) {
			return false;
		}

		let group_name = "";

		for (let group of grouplist) {
			if (stamp_detail.group_id === group.group_id) {
				group_name = group.group_name;
			}
		}

		return (
			<div>
				<img id="stamp_img" alt="icon" src={this.createCanvas(group_name)} />
			</div>
		);
	};

	/** [Element] */
	UpdateFormDialog_UploadTable = () => {

		/* Translate function */
		let { t } = this.props;

		// let { FieldControl } = this;

		/* */
		let {
			// applicationList, applicationDate, 
			stamp_detail,
			//  fields,
		} = this.state;

		// let groupItems = this.GroupItems();

		/* */
		let users = this.state.create.users;
		let rows = [];

		let userNameList = [];

		for (let i = 0; i < users.length; i++) {
			let user = users[i];
			userNameList.push(<div key={i}><span>{user['full_name']}</span></div>);
		}

		/* */
		rows.push(
			<fieldset key="stamp_field" id={`stamp`} className="stamp-item-row">
				<div className="stamp-item">
					{/* <Form.Row>
						<FieldControl
							as="select"
							name={`group_id`}
							xs={12}
							md={6}
							onChange={(ev) => {
								this.onChangeHandler(ev);
								this.eventClickButton(ev);
							}}
						>
							{groupItems}
						</FieldControl>
					</Form.Row> */}
					<input type="hidden" name="stamp_id" defaultValue={stamp_detail['stamp_id']} />
					<input type="hidden" name="group_id" defaultValue={stamp_detail['group_id']} />
					<input type="hidden" name="tenant_id" defaultValue={stamp_detail['tenant_id']} />
					<Form.Row>
						<this.setCanvasUpdate />
					</Form.Row>
					<Form.Row>
						<Form.Label>{t("common:settings.stamp.certificate.file-uploaded")}</Form.Label>
						<Form.Group className="">
							<div className="custom-form-outline form-group col-md-12 col-12">
								<div><span>{stamp_detail['file_name'] || "なし"}</span></div>
							</div>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group className="">
							<Form.Label>{t("common:settings.stamp.certificate.file-select")}</Form.Label>
							<Form.Control type="file" name="upload" xs={12} md={6} />
						</Form.Group>
						<Form.Group className="">
							<Form.Label>{t("common:settings.stamp.certificate.password")}</Form.Label>
							<Form.Control type="password" name="password" xs={12} md={6} placeholder={t("common:settings.stamp.certificate.password")} />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Label>{t("common:settings.stamp.user-list")}</Form.Label>
						<Form.Group className="">
							<div className="custom-form-outline form-group col-md-12 col-12">
								{userNameList}
							</div>
						</Form.Group>
					</Form.Row>
				</div>
			</fieldset>
		);

		/* */
		return (
			<div id="settings-stamp" className="">

				{/* <input type="file" id="upload" accept="image/*" /> */}

				{/*  */}
				<div className="lz-m-10">

					{/*  */}
					<div className="row">
						<div className="col-xl-12">
							<fieldset id="stamp-input-form" className="table-wrap">
								<div className="stamp">
									<input type="hidden" name="action_name" defaultValue="update" />
									<div id="stamp-form" className="stamp-itemlist">
										{rows}
									</div>
								</div>
								<div className="col-xl-12">
									<Form.Check type="checkbox" name="active_flg" label={t('common:settings.user.active')} defaultChecked="checked" />
								</div>
							</fieldset>
						</div>
					</div>

				</div>

			</div>
		);

	}

	/* */
	async StampUpdate(screenForm) {

		/* Translate Function */
		// let { t } = this.props;
		/* */
		let { modal, datalist } = this.state;
		/* */
		let users = this.state.create.users;
		/* */
		let form = document.querySelector("#stamp-input-form");

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ modal });

		// Get stamp image.
		// let stamp_img = document.getElementById('stamp_img').getAttribute("src");

		/* Find "certificate_file" from form */
		let certificate_file = "";
		let certificate_file_name = "";
		let certificate_extension = "";

		if (form.elements['upload']['files']['length'] !== 0) {
			certificate_file = form.elements['upload']['files'][0];
			certificate_file_name = certificate_file.name;
			certificate_extension = certificate_file.name.split('.').pop();

			/* Convert file to BASE64 */
			certificate_file = await this.convertFileToBase64URL(certificate_file).then((base64) => {
				return base64.replace(/^data:.+;base64,/, '');
			}).catch((error) => {
				console.log("Error", error);
			});
		}

		// Set api data
		let data = {
			"stamp_id": form.elements["stamp_id"]["value"],
			"tenant_id": form.elements["tenant_id"]["value"],
			"group_id": form.elements["group_id"]["value"],
			"certificate_file": certificate_file,
			"certificate_file_name": certificate_file_name,
			"certificate_extension": certificate_extension,
			"certificate_password": form.elements["password"]["value"],
			"status": form.elements["active_flg"]["checked"] ? 0 : 1,
		};

		let users_data = [];

		for (let i = 0; i < users.length; i++) {

			let user = users[i];

			/* */
			users_data.push({
				"tenant_id": user["tenant_id"],
				"user_id": user["user_id"],
				"email": user["email"],
			});
		}

		data["users"] = users_data;

		/* */
		Service.UpdateStamp(data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			console.log("error", error);

		});

	}

	/* */
	async StampSave() {
		/* Translate Function */
		let { t } = this.props;
		let { formData, create, modal } = this.state;

		/* Find "certificate_file" from form */
		let certificate_file = "";
		let certificate_file_name = "";
		let certificate_extension = "";

		if (formData["cert_file"] && formData["cert_file_name"]) {
			// certificate_file = formData["cert_file"];
			certificate_file_name = formData["cert_file_name"];
			certificate_extension = formData["cert_file_name"].split('.').pop();

			/* Convert file to BASE64 */
			certificate_file = await this.convertFileToBase64URL(formData["cert_file"]).then((base64) => {
				return base64.replace(/^data:.+;base64,/, '');
			}).catch((error) => {
				console.log("Error", error);
			});
		} else if (formData.hasOwnProperty("base64_cert")) {
			certificate_file = formData["base64_cert"];
		}

		// Set api data
		let data = {
			"group_id": formData["group_id"],
			"group_custom_label": formData["group_custom_label"],
			"stamp_img": formData["stamp_img"],
			"certificate_file": certificate_file,
			"certificate_file_name": certificate_file_name,
			"certificate_extension": certificate_extension,
			"certificate_password": formData["cert_password"],
			"users": [],
			"status": formData["active_flg"],
		};
		// "stamp_id": form.elements["stamp_id"]["value"],
		// "tenant_id": form.elements["tenant_id"]["value"],

		for (let user of create.users) {
			data["users"].push({
				"tenant_id": user["tenant_id"],
				"user_id": user["user_id"],
				"email": user["email"],
			});
		}

		if (formData["operation_type"] === "insert") {
			/* */
			Service.SaveStamp(data).then(resp => {
				// console.log(resp);
				modal.props.show = false;
				this.setState({ modal, datalist: null });
			}).catch(err => {
				console.log("error", err.response);
				this.SaveErrorHandler(err.response);
			});
		} else if (formData["operation_type"] === "update") {
			data["stamp_id"] = formData["stamp_id"];
			data["tenant_id"] = formData["tenant_id"];
			/* */
			Service.UpdateStamp(data).then(resp => {
				// console.log(resp);
				modal.props.show = false;
				this.setState({ modal, datalist: null });
			}).catch(err => {
				console.log("error", err.response);
				this.SaveErrorHandler(err.response);
			});
		} else {
			modal.body = t("common:message.error.form-data-missing-operation-type");
			this.setState({ modal });
		}
	}

	SaveErrorHandler = (error) => {
		let { t } = this.props;
		let { modal } = this.state;

		let errMessage = error.data.message;
		if (error.data.hasOwnProperty("error")) {
			errMessage = error.data.error;
		}

		modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
		modal.action = [(
			<Button key="ok" variant="primary" onClick={(ev) => {
				let { modal } = this.state;
				modal.props.size = "xl";
				modal.body = this.CreateFormDialog_UploadTable;
				modal.action = [(
					<Button key="return" variant="primary" onClick={this.openCreateFormDialog_ReturnUserForm}>
						<span>{t("common:auth.general.back")}</span>
					</Button>
				), (
					<Button key="ok" type="submit" variant="primary" >
						<span>{t("common:general.ok")}</span>
					</Button>
				)];
				this.setState({ modal });
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		)];

		this.setState({ modal });
	}

	/* */
	async StampDelete(stamp_id) {
		/* Translate Function */
		let { t } = this.props;
		let { modal } = this.state;

		/* */
		Service.DeleteStamp({
			"stamp_id": stamp_id,
		}).then(resp => {
			// console.log(resp);
			modal.props.show = false;
			this.setState({ modal, datalist: null });
		}).catch(err => {
			console.log("error", err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			} 
			modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
			modal.action = [(
				<Button key="ok" variant="primary" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, () => {
						this.StampDelete(stamp_id);
					});
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	/** Convert input[file] to Base64URL (string) */
	convertFileToBase64URL(file) {

		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	}

	getTagDiff(tagsA, tagsB) {
		const same = tagsA.filter((_item) => { return tagsB.find((_item2) => _item2.id === _item.id) })
		const sameIds = same.map((_item) => _item.id)

		return {
			left: tagsA.filter((_item) => { return !sameIds.includes(_item.id) }),
			same: same,
			right: tagsB.filter((_item) => { return !sameIds.includes(_item.id) }),
		}
	}
}

export default withTranslation()(Stamp);
